import React from "react";
import { useAppStore } from "../../store";
import Earned from "../../components/sections/earned";
import Payouts from "../../components/sections/payouts";

export default function Transactions() {
  const { doctor } = useAppStore();

  return (
    <div className="pt-4 flex flex-wrap md:justify-between">
      <div className="bg-white p-4 border md:w-[48%] w-full h-56 mb-4 rounded-md flex flex-col justify-center items-center">
        <h3 className="text-xl mb-2">Total Earnings</h3>
        <h3 className="text-3xl font-semibold">GH₵{doctor?.totalEarned}.00</h3>
        <p className="mt-4 text-base text-gray-500">
          This is your all time earnings with Medpharma.
        </p>
      </div>
      <div className="bg-white p-4 border md:w-[48%] w-full h-56 mb-4 rounded-md flex flex-col justify-center items-center">
        <h3 className="text-xl mb-2">Available Earnings</h3>
        <h3 className="text-3xl font-semibold">GH₵{doctor?.balance}.00</h3>
        <p className="mt-4 text-base text-gray-500">
          Payments are done weekly every Monday.
        </p>
      </div>
      <Earned />
      <Payouts />
    </div>
  );
}
