import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import AuthRoutes from "./auth.routes";
import Main from "../pages/main";
import Appointments from "../pages/appointments";
import Profile from "../pages/profile";
import Details from "../pages/appointments/details";
import Reschedule from "../pages/appointments/reschedule";
import Cancel from "../pages/appointments/cancel";
import Transactions from "../pages/transactions";
import VoiceMeeting from "../pages/chime/voice-meeting";
import VideoMeeting from "../pages/chime/video-meeting";
import Chat from "../pages/chime/chat";
import SetUpAccount from "../pages/auth/set-up-account";
import DoctorReport from "../pages/appointments/doctor-report";
import NotFound from "../pages/not-found";
import Availability from "../pages/availability";

export const router = createBrowserRouter([
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/sign-in",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Register />,
  },
  {
    element: <AuthRoutes />,
    children: [
      {
        path: "/set-up",
        element: <SetUpAccount />,
      },
      {
        path: "/:id/voice-call",
        element: <VoiceMeeting />,
      },
      {
        path: "/:id/video-meeting",
        element: <VideoMeeting />,
      },
      {
        path: "/",
        element: <Main />,
        children: [
          {
            element: <Appointments />,
            index: true,
          },
          {
            path: "/appointments/:id",
            element: <Details />,
          },
          {
            path: "/appointments/:id/report",
            element: <DoctorReport />,
          },
          {
            path: "/appointments/reschedule/:id",
            element: <Reschedule />,
          },
          {
            path: "/appointments/cancel/:id",
            element: <Cancel />,
          },
          {
            path: "/appointments/chat",
            element: <Chat />,
          },
          {
            path: "/schedule",
            element: <Availability />,
          },
          {
            path: "/transactions",
            element: <Transactions />,
          },
          {
            path: "/profile/:id",
            element: <Profile />,
          },
        ],
      },
    ],
  },
]);
