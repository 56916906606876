import React from "react";
import doc from "../../assets/images/user.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function AppointmentCard(props: any) {
  const navigate = useNavigate();
  const { data } = props;
  return (
    <div
      onClick={() => navigate(`/appointments/${data.id}`)}
      className="py-4 m-2 cursor-pointer rounded shadow-sm w-80 sm:w-80 bg-gray-50"
    >
      <div className="flex p-4 space-x-4 sm:px-8">
        <div className="flex-shrink-0 w-16 h-16 rounded-full bg-gray-300">
          <img src={doc} alt="" className="" />
        </div>
        <div className="flex-1 py-2 space-y-4">
          <div className="w-full h-3 rounded font-semibold text-sm">
            {data.doctorName}
          </div>
          <div className="w-5/6 h-3 rounded text-gray-500 text-xs">
            {data.doctor.institution}
          </div>
        </div>
      </div>
      <div className="p-4 space-y-2 sm:px-8">
        {/* <div className="w-full rounded text-gray-700">{data.service.name}</div> */}
        <div className="w-full rounded text-gray-500 text-xs">{`${moment(
          data.date,
          "DD-MM-YYYY"
        ).format("Do MMMM, YYYY")}`}</div>
        <div className="w-3/4 flex rounded">
          <span className="text-xs text-gray-500">{data.startTime}</span>{" "}
          <span className="text-xs text-gray-500 mx-2">-</span>
          <span className="text-xs text-gray-500">{data.endTime}</span>
        </div>
      </div>
    </div>
  );
}
