import React from "react";
import { PhoneOutlined, VideoCameraFilled } from "@ant-design/icons";
import { IoChatbubbles } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import doc from "../../assets/images/user.png";

export default function ApptCard(props: any) {
  const navigate = useNavigate();
  const { data } = props;

  return (
    <div
      onClick={() => navigate(`/appointments/${data.id}`)}
      className="flex justify-start cursor-pointer items-center p-0.5 shadow-sm my-1 lg:h-20 h-16 w-full rounded-lg border border-gray-50 bg-white"
    >
      <div className="bg-[#19B0FA] text-white flex flex-col justify-evenly items-center h-full w-[20%] rounded-lg">
        <span className="md:text-sm text-xs text-white">{data.startTime}</span>
        <span className="md:text-sm text-xs text-white">{data.endTime}</span>
      </div>

      <div className="flex justify-start items-center mx-4 h-full">
        <div className="lg:h-16 lg:w-16 h-12 rounded-full">
          <img src={doc} alt="" className="h-full" />
        </div>

        <div className="mx-4 flex flex-col justify-center items-start h-full">
          <h2 className="font-semibold text-xs">{data.patientName}</h2>
          <p className=" text-gray-400 text-xs mb-1">{`${moment(
            data.date,
            "DD-MM-YYYY"
          ).format("Do MMMM, YYYY")}`}</p>
          {data.appointmentType === "video" && (
            <div className="flex text-[#06509E]">
              <VideoCameraFilled />{" "}
            </div>
          )}
          {data.appointmentType === "voice" && (
            <div className="flex text-[#06509E]">
              <PhoneOutlined />{" "}
            </div>
          )}
          {data.appointmentType === "chat" && (
            <div className="flex text-[#06509E]">
              <IoChatbubbles />{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
