/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Collapse, CollapseProps, Image, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Colors from "../../constants/Colors";
import { FaChevronLeft } from "react-icons/fa";
import DataProvider from "../../apis";
import LoadWrapper from "../../components/elements/load-wrapper";
import UploadPrescription from "../../components/sections/upload-prescription";

export default function DoctorReport() {
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [patient, setPatient] = useState<any>(null);
  const [prescription, setPrescription] = useState<string | null>(null);
  const [recommendations, setRecommendations] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");
  const [diagnosis, setDiagnosis] = useState<any>("");

  const onFetch = useCallback(async (id: any) => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getReport(id);
      if (data) {
        setData(data);
        setDiagnosis(data.diagnosis);
        setRecommendations(data.recommendations);
        setRemarks(data.remarks);
        setPrescription(data.prescription);
        if (data.appointment && data.appointment.user) {
          setPatient(data.appointment.user);
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const { id } = params;
    onFetch(id);
  }, [params, onFetch]);

  async function onSave() {
    if (!recommendations) {
      return api["warning"]({
        message: "Recommendations",
        description: "Add a recommendation",
      });
    }

    let payload: any = {};
    payload.recommendations = recommendations;

    if (prescription) {
      payload.prescription = prescription;
    }
    if (diagnosis) {
      payload.diagnosis = diagnosis;
    }
    if (remarks) {
      payload.remarks = remarks;
    }

    try {
      setLoading(true);
      const { id } = params;
      if (id) {
        const { data } = await DataProvider.createReport(id, payload);
        if (data) {
          setData(data);
          setDiagnosis(data.diagnosis);
          setRecommendations(data.recommendations);
          setRemarks(data.remarks);
          setPrescription(data.prescription);
          if (data.appointment && data.appointment.user) {
            setPatient(data.appointment.user);
          }

          api["success"]({
            message: "Saved",
            description: "Report added successfully.",
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return api["error"]({
        message: "Failed",
        description: error.message,
      });
    }
  }
  async function onUpdate() {
    if (!recommendations) {
      return api["warning"]({
        message: "Recommendations",
        description: "Add a recommendation",
      });
    }

    let payload: any = {};
    payload.recommendations = recommendations;

    if (prescription) {
      payload.prescription = prescription;
    }
    if (diagnosis) {
      payload.diagnosis = diagnosis;
    }
    if (remarks) {
      payload.remarks = remarks;
    }
    try {
      setLoading(true);
      const { id } = params;
      if (id) {
        const { data } = await DataProvider.updateReport(id, payload);
        if (data) {
          setData(data);
          setDiagnosis(data.diagnosis);
          setRecommendations(data.recommendations);
          setRemarks(data.remarks);
          setPrescription(data.prescription);
          if (data.appointment && data.appointment.user) {
            setPatient(data.appointment.user);
          }
          api["success"]({
            message: "Updated",
            description: "Report updated successfully.",
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      return api["error"]({
        message: "Failed To Update",
        description: error.message,
      });
    }
  }

  return (
    <div className="pt-3">
      <div className="bg-white rounded-lg w-full md:min-h-[85vh] h-full py-2 px-2 border">
        <Button
          type="link"
          onClick={() => navigate(-1)}
          style={{ color: Colors.primaryBlue }}
          className="my-1 flex justify-evenly items-center"
        >
          <FaChevronLeft /> <span className="mx-1">Back</span>
        </Button>
        <LoadWrapper loading={loading}>
          <div className="h-full w-full grid md:grid-cols-2 grid-cols-1 gap-5 ">
            <div className="">
              <div className="border rounded flex flex-col md:p-4 p-2 mb-4">
                <h3 className="font-semibold text-lg mb-2">Prescription</h3>
                {prescription ? (
                  <div className="">
                    <Image width={300} src={prescription} alt="prescription" />
                  </div>
                ) : (
                  <UploadPrescription
                    prescription={prescription}
                    setPrescription={setPrescription}
                  />
                )}
              </div>
            </div>
            <div className="">
              <div className="border rounded flex flex-col md:p-4 p-2 mb-4">
                <div>
                  <p className="mb-1 md:text-base text-xs font-medium">
                    Diagnosis
                  </p>
                  <textarea
                    className="border w-full outline-none rounded-md p-2 md:text-base text-xs"
                    name=""
                    value={diagnosis}
                    onChange={(e) => setDiagnosis(e.target.value)}
                    placeholder="Diagnosis ..."
                    id=""
                  ></textarea>
                </div>

                <div>
                  <p className="mb-1 md:text-base text-xs font-medium">
                    Remarks
                  </p>
                  <textarea
                    className="border w-full outline-none rounded-md p-2 md:text-base text-xs"
                    name=""
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    placeholder="Type your remarks here..."
                    id=""
                  ></textarea>
                </div>
                <div>
                  <p className="mb-1 md:text-base text-xs font-medium">
                    Recommendations
                  </p>
                  <textarea
                    className="border w-full outline-none rounded-md p-2 md:text-base text-xs"
                    name=""
                    value={recommendations}
                    onChange={(e) => setRecommendations(e.target.value)}
                    placeholder="Type your recommendations here..."
                    id=""
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            {data ? (
              <Button
                onClick={onUpdate}
                type="primary"
                size="large"
                style={{ backgroundColor: Colors.primary }}
                className=""
              >
                {loading ? "Updating" : "Update Report"}
              </Button>
            ) : (
              <Button
                onClick={onSave}
                type="primary"
                size="large"
                style={{ backgroundColor: Colors.primary }}
              >
                {loading ? "Loading" : "Save Report"}
              </Button>
            )}
          </div>
        </LoadWrapper>
      </div>
      {contextHolder}
    </div>
  );
}
