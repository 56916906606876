/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import moment from "moment";
import { FaChevronRight } from "react-icons/fa6";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import apt from "../../assets/images/apt.png";
import ApptCard from "./appt-card";
import Loader from "../elements/loader";

export default function RecentAppts(props: any) {
  const navigate = useNavigate();
  const { loading, data, date } = props;

  return (
    <div className="min-h-64 px-4">
      <div className="flex items-center justify-between">
        <h2 className="text-sm">
          Appointments - {moment(date).format("Do MMMM YYYY")}
        </h2>
        {/* <Button
          onClick={() => navigate("appointments/all")}
          type="link"
          className="flex text-sm items-center text-[#06509E]"
        >
          View all <FaChevronRight className="ml-1" />
        </Button> */}
      </div>

      <div className="h-full flex flex-col justify-evenly items-center pt-4">
        {loading ? (
          <Loader num={3} />
        ) : data !== null && data.length > 0 ? (
          data.map((v: any, idx: any) => <ApptCard key={idx} data={v} />)
        ) : (
          <div className="h-full flex flex-col justify-center items-center w-full">
            <div className="flex flex-col justify-center items-center">
              <img src={apt} alt="" className="h-32" />
              <h1 className="text-gray-500">No upcoming appointments...</h1>
              <p className="text-gray-500">
                Search for a doctor & Book appointment below.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
