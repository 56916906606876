import React, { useEffect, useState } from "react";

export default function CustomInput(props: any) {
  const [error, setError] = useState(false);
  const setText = (text: string) => {
    props.onChangeText(text);
  };

  useEffect(() => {
    if (props.required && props.errorMessage && props.value === null) {
      setError(false);
    }
  }, [props.required, props.errorMessage, props.value]);

  return (
    <div className="w-full my-4">
      <div className="my-2">
        {props.title && (
          <p className="mb-1 md:text-base text-xs">{props.title}</p>
        )}
        <input
          className={`${
            props.style ? props.style : "md:h-12 h-10 md:text-base text-xs"
          } rounded-lg bg-white px-2 outline-none border w-full`}
          type={props.type ? props.type : "text"}
          name={props.name}
          // id="custom"
          value={props.value}
          onChange={(e) => setText(e.target.value)}
          placeholder={props.placeholder}
        />
      </div>
      {error && (
        <div className="text-red-500 text-sm">{props.errorMessage}</div>
      )}
    </div>
  );
}
