// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  // browserPopupRedirectResolver,
  browserSessionPersistence,
  initializeAuth,
} from "@firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhNo5MqhnbIJ7d2tiQn_rRWT_oqqlYJqw",
  authDomain: "medpharma-50900.firebaseapp.com",
  databaseURL: "https://medpharma-50900.firebaseio.com",
  projectId: "medpharma-50900",
  storageBucket: "medpharma-50900.appspot.com",
  messagingSenderId: "92923960467",
  appId: "1:92923960467:web:faa5b9ac6e3382957f4166",
  measurementId: "G-44BBKL2PW9",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app)
const auth = initializeAuth(app, {
  persistence: browserSessionPersistence,
  // popupRedirectResolver: cordovaPopupRedirectResolver, //browserPopupRedirectResolver,
});

export { analytics, app, firebaseConfig, auth, db };
