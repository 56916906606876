import React, { useCallback, useEffect, useState } from "react";
import LoadWrapper from "../elements/load-wrapper";
import DataProvider from "../../apis";
import CustomInput from "../form/custom-input";
import { Button, notification } from "antd";
import Colors from "../../constants/Colors";
import { useAppStore } from "../../store";
import { NotificationType } from "../../utils";

export default function PaymentDetails(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const { doctor } = useAppStore();
  const { tab } = props;
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState<any>(null);
  const [accountType, setAccountType] = useState<string>("");
  const [accountName, setAccountName] = useState<string>("");
  const [accountProvider, setAccountProvider] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [providerBranch, setProviderBranch] = useState<string>("");
  const [loadingX, setLoadingX] = useState<boolean>(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  const getProfile = useCallback(async () => {
    setLoading(true);
    const { data } = await DataProvider.getDoctorProfile();
    if (data.paymentDetails) {
      setAccountName(data.paymentDetails.accountName);
      setAccountNumber(data.paymentDetails.accountNumber);
      setAccountProvider(data.paymentDetails.accountProvider);
      setAccountType(data.paymentDetails.accountType);
      setProviderBranch(data.paymentDetails.providerBranch);
    }

    setLoading(false);
  }, []);

  useEffect(() => {
    if (tab === "4") {
      getProfile();
    }
  }, [tab, getProfile]);

  async function onSubmit() {
    const payload = {
      paymentDetails: {
        accountType,
        accountProvider,
        providerBranch,
        accountNumber,
        accountName,
      },
    };

    try {
      if (doctor) {
        setLoadingX(true);
        await DataProvider.updateDoctorProfile(doctor.id, payload);
        setLoadingX(false);
        getProfile();
      }
    } catch (error: any) {
      setLoadingX(false);
      return openNotificationWithIcon("error", "Failed", error.message);
    }
  }

  const list = [
    "Payments are done on the 3 day of every month ending.",
    "In case theres a change in your account details. Update your account details at least 3 days before payout date.",
    "Account balance are credited for only completed appointment, where report has been signed and uploaded.",
  ];

  return (
    <div className="bg-white grid md:grid-cols-2 grid-cols-1 gap-5 border p-4 min-h-[70vh] rounded-md">
      <div className="">
        <div className="p-4 bg-[#F0F9FF] border rounded-lg">
          <h3 className="font-semibold mb-4">Payment Guidelines</h3>
          <ul itemType="A">
            {list.map((v, idx) => (
              <li key={idx} className="mb-2">
                {idx + 1}. {v}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="border rounded-lg p-4">
        <h3 className="font-semibold mb-2">Payment Details</h3>
        <LoadWrapper loading={loading}>
          <div>
            <label htmlFor="accountType" className="md:text-base text-xs">
              Payment method
            </label>
            <select
              value={accountType}
              onChange={(e) => {
                setAccountType(e.target.value);
                setAccountProvider("");
                setAccountNumber("");
              }}
              name="accountType"
              id=""
              className="rounded-lg bg-white px-2 mt-1 outline-none border w-full md:h-12 h-10 md:text-base text-xs"
            >
              <option value="momo">Mobile Money</option>
              <option value="bank">Bank</option>
            </select>
          </div>
          <CustomInput
            onChangeText={setAccountName}
            value={accountName}
            title="Account name"
            placeholder="Enter the name associated with the account"
          />
          {accountType === "momo" && (
            <div>
              <label htmlFor="accountType" className="md:text-base text-xs">
                Mobile money provider
              </label>
              <select
                value={accountProvider}
                onChange={(e) => setAccountProvider(e.target.value)}
                name="accountType"
                id=""
                className="rounded-lg bg-white px-2 mt-1 outline-none border w-full md:h-12 h-10 md:text-base text-xs"
              >
                <option value="mtn">MTN</option>
                <option value="telecel">Telecel Cash</option>
                <option value="airteltigo">AirtelTigo</option>
              </select>
            </div>
          )}

          {accountType === "bank" && (
            <>
              <CustomInput
                onChangeText={setAccountProvider}
                value={accountProvider}
                title="Bank name"
                placeholder="Enter your bank name"
              />
              <CustomInput
                onChangeText={setProviderBranch}
                value={providerBranch}
                title="Bank branch"
                placeholder="Enter the bank branch"
              />
            </>
          )}

          <CustomInput
            onChangeText={setAccountNumber}
            value={accountNumber}
            title={
              accountType === "bank" ? "Account number" : "Mobile money number"
            }
            placeholder={
              accountType === "bank"
                ? "Enter your bank account number"
                : "Enter your mobile money number"
            }
          />

          <Button
            type="primary"
            style={{ background: Colors.primary }}
            onClick={onSubmit}
            className="px-8"
          >
            {loadingX ? "Saving..." : "Save"}
          </Button>
        </LoadWrapper>
      </div>
      {contextHolder}
    </div>
  );
}
