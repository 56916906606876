import React from "react";
// import { MeetingProvider } from "amazon-chime-sdk-component-library-react";
// import { useLocation } from "react-router-dom";
// import MeetingWidget from "../../components/elements/meeting-widget";

export default function VoiceMeeting() {
  // const location = useLocation();
  // const meet = location.state;

  return (
    <div className="pt-3">
      <div className="bg-white flex justify-center rounded-lg w-full md:h-[84vh] h-full py-2 px-2 border">
        <div className="w-full h-full flex flex-col justify-start items-center rounded-lg">
          {/* {meet.meeting && (
            <MeetingProvider>
              <MeetingWidget meeting={meet.meeting} />
            </MeetingProvider>
          )} */}
        </div>
      </div>
    </div>
  );
}
