import { create } from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

export interface doctorDetails {
    id: number;
    active: boolean;
    approved: boolean;
    balance?: number;
    totalEarned?: number;
    institution?: string;
    licenseId?: string;
    speciality?: string;
    online: boolean;
    appInstanceUserArn?: null | string;
    profile_image?: null | string;
    paymentDetails?: any;
    schedules?: any[] | null;
    services?: any[] | null;
    role: string;
    user?: any;
    createdAt: Date;
    updatedAt: Date;
}

export interface doctorToken {
    token: string;
}

interface AppStorageProps {
    apiKey: string;
    loggedIn : boolean;
    doctor: doctorDetails | null;
    setDoctor: (doctor: doctorDetails) => void;
    token: doctorToken | null;
    setToken: (token: doctorToken) => void;
    logOut: () => void;
    user?: any;
    setUser: (user: any) => void;
    rehydrated: boolean;
    setRehydrated: (rehydrated: boolean) => void;
}

export const useAppStore = create<AppStorageProps>()(
    persist(
        set => ({
            apiKey: "",
            loggedIn: false,
            doctor: null,
            setDoctor: (doctor: doctorDetails) => set({doctor: doctor, loggedIn: true}),
            token: null,
            setToken: (token: doctorToken) => set({token: token}),
            logOut: () => set({loggedIn: false, doctor: null, token: null, user: null}),
            user: null,
            setUser: (user: any) => set({user: user}),
            rehydrated: false,
            setRehydrated: (rehydrated: boolean) => set({ rehydrated }),
        }),
        {
            name: "app-storage",
            storage: createJSONStorage(() => localStorage),
             onRehydrateStorage: () => (state) => {
                state?.setRehydrated(true);
            },
        }
    )
)
