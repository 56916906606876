/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import type { CalendarProps } from "antd";
import dayLocaleData from "dayjs/plugin/localeData";
import { Calendar, theme, Button, notification } from "antd";
import DataProvider from "../../apis";
import { useAppStore } from "../../store";
import LoadWrapper from "../../components/elements/load-wrapper";
import moment from "moment";
import Colors from "../../constants/Colors";
import { NotificationType, schedulePassed } from "../../utils";
import { availableTimeSlots } from "../../utils/date";

dayjs.extend(dayLocaleData);

type TimeFrame = {
  id?: number;
  startTime: string;
  endTime: string;
  booked?: boolean;
  appointmentId?: null | number;
};

type Schedule = {
  date: string;
  timeFrames?: TimeFrame[];
};

export default function Availability() {
  const [api, contextHolder] = notification.useNotification();
  const { doctor } = useAppStore();
  const [schedule, setSchedule] = useState<any>(null);
  const [time, setTime] = useState<null>(null);
  const [timeframes, setTimeframes] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingx, setLoadingx] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const [data, setData] = useState<any[]>([]);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string,
    btn?: ReactNode
  ) => {
    api[type]({
      message: title,
      description: message,
      btn: btn ? btn : null,
    });
  };

  const { token } = theme.useToken();
  const wrapperStyle: React.CSSProperties = {
    width: "100%",
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>["mode"]) => {
    // console.log(value.format("YYYY-MM-DD"), mode);
  };

  const handleChange = (value: Dayjs) => {
    // const selectedDateData = data.find(
    //   (item) => item.date === value.format("DD-MM-YYYY")
    // );
    setDate(new Date(value.toDate()));
  };

  const fetchData = useCallback(
    async (date: any) => {
      try {
        setLoading(true);
        if (doctor) {
          const { data } = await DataProvider.getDoctorSchedules(doctor?.id);
          if (data !== null) {
            setData(data);
            const flt = data.find(
              (item: any) => item.date === moment(date).format("DD-MM-YYYY")
            );
            if (flt) {
              setSchedule(flt);
              setTimeframes(flt.timeFrames);
            } else setTimeframes([]);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [doctor]
  );

  function updateTimeframes(params: any) {
    const existIndex = timeframes.findIndex(
      (item) => item.startTime === params.startTime
    );

    if (existIndex !== -1) {
      // If the object already exists, remove it from the timeframes array
      const updatedTimeframes = [...timeframes];
      updatedTimeframes.splice(existIndex, 1);
      setTimeframes(updatedTimeframes);
    } else {
      // If the object doesn't exist, add it to the timeframes array
      setTimeframes([...timeframes, params]);
    }
  }

  async function saveSchedule(params: any) {
    if (timeframes.length < 1) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Select at least one timeframe!"
      );
    }
    const payload = {
      date: moment(date).format("DD-MM-YYYY").toString(),
      timeFrames: timeframes,
    };
    try {
      setLoadingx(true);
      await DataProvider.saveSchedule(payload);
      openNotificationWithIcon(
        "success",
        "Doctor Schedule",
        "Schedule created successfully!"
      );
      setLoadingx(false);
      fetchData(date);
    } catch (error: any) {
      setLoadingx(false);
      return openNotificationWithIcon("warning", "Warning", error.message);
    }
  }

  useEffect(() => {
    fetchData(date);
  }, [fetchData, date]);

  return (
    <div className="pt-4">
      <div className="bg-white border rounded-lg p-4 h-full">
        <div className="w-full grid md:grid-cols-2 grid-cols-1 gap-5 ">
          <div>
            <div style={wrapperStyle}>
              <Calendar
                fullscreen={false}
                onChange={handleChange}
                onPanelChange={onPanelChange}
              />
            </div>
          </div>
          {availableTimeSlots.map((v, idx) => (
            <LoadWrapper loading={loading} key={idx}>
              <div className="border p-4 rounded-md" key={idx}>
                <h4 className="font-semibold flex justify-center">{v.name}</h4>
                <div className="flex sm:justify-between justify-evenly flex-wrap items-start my-2 md:px-8">
                  {v.time.map((i, ix) => {
                    const isTimeAvailable = timeframes.some(
                      (item) => item.startTime === i.startTime
                    );
                    const passed: boolean = schedulePassed(
                      moment(date).format("DD-MM-YYYY"),
                      i.startTime
                    );

                    return (
                      <Button
                        disabled={passed}
                        key={ix}
                        onClick={() => updateTimeframes(i)}
                        style={{
                          width: 140,
                          backgroundColor:
                            isTimeAvailable && !passed ? Colors.primary : "",
                        }}
                        type={isTimeAvailable ? "primary" : "default"}
                        className="text-xs m-2"
                      >
                        {i.startTime} - {i.endTime}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </LoadWrapper>
          ))}
        </div>
        <div className="flex justify-center my-4">
          <Button
            onClick={saveSchedule}
            size="large"
            type="primary"
            style={{
              width: 250,
              backgroundColor: Colors.primary,
            }}
            className=""
          >
            {loadingx ? "Saving..." : "Save"}
          </Button>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
