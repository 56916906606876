import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataProvider from "../../apis";
// import doc from "../../assets/images/user.png";
import LoaderAnimate from "../../components/elements/loader-animate";
import moment from "moment";
import { CalendarFilled } from "@ant-design/icons";
// import { IoChatbubbles } from "react-icons/io5";
import { Button, Collapse, CollapseProps } from "antd";
import Colors from "../../constants/Colors";
import { FaChevronLeft } from "react-icons/fa6";
import Empty from "../../components/elements/empty";
import AppointmentCountdown from "../../components/elements/appointment-countdown";
import { checkAppointmentStatus } from "../../utils";
import PatientInfo from "../../components/sections/patient-info";
import PatientRecords from "../../components/sections/patient-records";
import JoinMeetingButton from "../../components/elements/join-meeting-button";

export default function Details() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [appt, setAppt] = useState<any>(null);
  const [status, setStatus] = useState<boolean>(true);
  const [count, setCount] = useState<boolean>(false);
  const [patient, setPatient] = useState<any>(null);
  const [records, setRecords] = useState<any>(null);
  const [targetDate, setTargetDate] = useState({
    date: "",
    startTime: "",
    endTime: "",
  });

  const onFetch = async (id: any) => {
    setLoading(true);
    try {
      const { data } = await DataProvider.getSingleAppointment(id);
      setAppt(data);
      const state = checkAppointmentStatus(
        data.date,
        data.startTime,
        data.endTime
      );

      if (state === "passed") {
        setStatus(true);
      } else if (state === "within-time") {
        setStatus(false);
      } else if (state === "waiting") {
        setTargetDate({
          date: data.date,
          startTime: data.startTime,
          endTime: data.endTime,
        });
        setCount(true);
      }

      if (data && data.user) {
        setPatient(data.user);
        const res = await DataProvider.getUserReports(data.user.id);
        setRecords(res.data);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { id } = params;
    onFetch(id);
  }, [params]);

  // const meet = {
  //   meeting: {
  //     mediaPlacement: {
  //       audioFallbackUrl:
  //         "wss://wss.k.m1.ue1.app.chime.aws:443/calls/ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //       audioHostUrl:
  //         "282de4b606c609e14a9556e16e6efa48.k.m1.ue1.app.chime.aws:3478",
  //       eventIngestionUrl:
  //         "https://data.svc.ue1.ingest.chime.aws/v1/client-events",
  //       screenDataUrl:
  //         "wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //       screenSharingUrl:
  //         "wss://bitpw.m1.ue1.app.chime.aws:443/v2/screen/ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //       screenViewingUrl:
  //         "wss://bitpw.m1.ue1.app.chime.aws:443/ws/connect?passcode=null&viewer_uuid=null&X-BitHub-Call-Id=ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //       signalingUrl:
  //         "wss://signal.m1.ue1.app.chime.aws/control/ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //       turnControlUrl:
  //         "https://2713.cell.us-east-1.meetings.chime.aws/v2/turn_sessions",
  //     },
  //     meetingId: "ce0fd94b-2b78-471d-a2d6-9b56a2082713",
  //     attendeeId: "f8afbb0c-a5d1-f307-144a-7ed3996fef2f",
  //     externalUserId: "acb69e0c-5e18-46fa-86ac-9f464c947177",
  //     joinToken:
  //       "ZjhhZmJiMGMtYTVkMS1mMzA3LTE0NGEtN2VkMzk5NmZlZjJmOmIzMzUzM2M5LTRkYjAtNDc4Mi1iMGQwLTI1ODI4ZWMzMzhjYg",
  //   },
  // };

  // function onJoinVideoMeeting() {
  //   const meeting = { ...appt.doctorMeetingDetails, meetingId: appt.meetingId };
  //   navigate(`/${appt.meetingId}/video-meeting`, {
  //     state: { meeting, appt },
  //   });

  //   // navigate(`/ce0fd94b-2b78-471d-a2d6-9b56a2082713/video-meeting`, {
  //   //   state: { ...meet, appt },
  //   // });
  // }

  // function onJoinChatMeeting() {
  //   navigate("/appointments/chat", {
  //     state: { meeting: appt.doctorMeetingDetails, appt },
  //   });
  // }

  // function onJoinVoiceMeeting() {
  //   const meeting = {
  //     ...appt.doctorMeetingDetails,
  //     meetingId: appt.meetingId,
  //   };
  //   navigate(`/${appt.meetingId}/voice-call`, {
  //     state: { meeting, appt },
  //   });
  // }

  function onCancel() {
    navigate(`/appointments/cancel/${appt.id}`);
  }

  function onReschedule() {
    navigate(`/appointments/reschedule/${appt.id}`);
  }

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: "Patient Information",
      children: patient && <PatientInfo data={patient} />,
    },
  ];

  return (
    <div className="pt-3">
      {loading ? (
        <div className="bg-white rounded-lg w-full min-h-[85vh] grid grid-cols-1 justify-center py-2 px-2 border">
          <LoaderAnimate />
        </div>
      ) : !loading && appt ? (
        <div className="bg-white rounded-lg w-full md:min-h-[85vh] h-full py-2 px-2 border">
          <Button
            type="link"
            onClick={() => navigate(-1)}
            style={{ color: Colors.primaryBlue }}
            className="my-1 flex justify-evenly items-center"
          >
            <FaChevronLeft /> <span className="mx-1">Back</span>
          </Button>
          <div className="h-full w-full grid md:grid-cols-2 grid-cols-1 gap-5 ">
            <div className="">
              {count && (
                <div className="mb-3">
                  <AppointmentCountdown
                    setStatus={setStatus}
                    date={targetDate.date}
                    startTime={targetDate.startTime}
                    endTime={targetDate.endTime}
                    onCountdownEnd={onFetch}
                    params={params.id}
                  />
                </div>
              )}
              <div className="py-1 pb-1 pt-2 border border-gray-200 rounded-md flex flex-wrap items-center justify-start">
                <JoinMeetingButton appt={appt} status={status} />
                <Button
                  disabled={status}
                  onClick={onReschedule}
                  style={{
                    color: status ? "" : Colors.primaryBlue,
                    borderColor: status ? "" : Colors.primaryBlue,
                  }}
                  className="mx-2 mb-2 sm:w-auto w-full flex justify-center items-center"
                >
                  <CalendarFilled /> Reschedule
                </Button>
                <Button
                  disabled={status}
                  onClick={onCancel}
                  type="primary"
                  style={{ backgroundColor: status ? "" : "red" }}
                  className="mb-2 sm:w-auto w-full mx-2"
                >
                  Cancel Appointment
                </Button>
              </div>
              <div className="p-1 border border-gray-200 rounded-md flex items-start justify-between my-4">
                <div className="flex flex-col text-xs">
                  <h4 className="text-gray-600 font-semibold mb-1">
                    Appointment Schedule
                  </h4>
                  <p className=" text-gray-400 text-xs mb-1">{`${moment(
                    appt.date,
                    "DD-MM-YYYY"
                  ).format("Do MMMM, YYYY")}`}</p>
                  <div className=" text-white flex items-center h-full w-[20%] ">
                    <span className="text-xs text-gray-400">
                      {appt.startTime}
                    </span>{" "}
                    <span className="text-xs text-gray-400 mx-2">-</span>
                    <span className="text-xs text-gray-400">
                      {appt.endTime}
                    </span>
                  </div>
                </div>
              </div>
              <div className="p-1 border border-gray-200 text-xs rounded-md flex flex-col items-center justify-center my-4">
                <div className="w-full flex flex-col justify-center items-center">
                  <Empty
                    title="Doctor Report"
                    imageStyle="h-16"
                    titleStyle="text-xs"
                    description=""
                    buttons={[
                      {
                        name: "View Report",
                        path: `/appointments/${appt.id}/report`,
                        type: "default",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="">
              {patient && (
                <div className="mb-4">
                  <Collapse expandIconPosition="end" items={items} />
                </div>
              )}

              <div className="mb-4">
                <PatientRecords data={records} />
              </div>

              <div className="flex flex-wrap justify-start items-center border border-gray-200 p-1 rounded-md my-4">
                <div className="mr-4">
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Have underlying disease?
                    </h4>
                    <p className="text-gray-400">
                      {appt.hasUnderlyingDisease ? "YES" : "NO"}
                    </p>
                  </div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Underlying diseases
                    </h4>
                    <p className="text-gray-400">
                      {appt.hasUnderlyingDisease
                        ? appt.underlyingDiseaseNotes
                        : "N/A"}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">
                      Takes Previous Medication?
                    </h4>
                    <p className="text-gray-400">
                      {appt.takesPreviousMedication ? "YES" : "NO"}
                    </p>
                  </div>
                  <div className="text-xs mb-2">
                    <h4 className="text-gray-500 font-semibold">Medication</h4>
                    <p className="text-gray-400">
                      {appt.takesPreviousMedication
                        ? appt.upreviousMedicationNotes
                        : "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start border border-gray-200 p-1 rounded-md my-4">
                <h5 className="text-gray-500 font-semibold text-xs">Notes</h5>
                <p className="text-gray-400 text-xs">{appt.complaint}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg w-full min-h-[85vh] grid grid-cols-1 justify-center py-2 px-2 border">
          <Empty
            imageStyle="md:h-44 h-24"
            titleStyle="md:text-lg text-sm"
            desStyle="md:text-base"
            title="Empty"
            buttons={[
              {
                name: "Refresh",
                event: () => {
                  const { id } = params;
                  onFetch(id);
                },
              },
              {
                name: "Go Back",
                event: () => navigate(-1),
              },
            ]}
            description="Appointment not found."
          />
        </div>
      )}
    </div>
  );
}
