import React, { useEffect, useState } from "react";
import CustomInput from "../form/custom-input";
import { Button, notification } from "antd";
import DataProvider from "../../apis";
import { useAppStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { NotificationType } from "../../utils";

export default function AccountSetup(props: any) {
  const [api, contextHolder] = notification.useNotification();
  const { setDoctor, user } = useAppStore();
  const navigate = useNavigate();
  const { setTab } = props;

  const [speciality, setSpeciality] = useState<string>("");
  const [license, setLicense] = useState<string>("");
  const [institution, setInstitution] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  useEffect(() => {
    if (!user) navigate("/signin");
  }, [user, navigate]);

  async function onSubmit() {
    if (institution.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Enter the institution you work with!"
      );
    }

    if (license.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Enter your license Id!"
      );
    }

    if (speciality.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Enter at your speciality!"
      );
    }

    if (bio.length < 3) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Type in a brief about yourself in the bio!"
      );
    }

    const payload = {
      institution,
      speciality,
      licenseId: license,
      userId: user.id,
      bio,
    };

    try {
      setLoading(true);
      const result = await DataProvider.onboardDoctor(payload);
      setDoctor(result.data);
      setLoading(false);
      setTab(1);
    } catch (error: any) {
      setLoading(false);
      return openNotificationWithIcon("error", "Failed", error.message);
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center md:my-8 my-12 px-4">
      <div className="lg:w-[50%] md:w-[70%] w-full">
        <div className="mb-4">
          <h1 className="md:text-3xl text-xl font-bold">Set up your account</h1>
          <p className=" md:text-base text-sm text-gray-500">
            Additional information to set up your account
          </p>
        </div>
        <div className="">
          <CustomInput
            onChangeText={setInstitution}
            value={institution}
            title="Institution"
            placeholder="Enter your place of work"
          />
          <CustomInput
            onChangeText={setLicense}
            value={license}
            title="License"
            placeholder="Enter your license ID"
          />
          <CustomInput
            onChangeText={setSpeciality}
            value={speciality}
            title="Speciality"
            placeholder="eg: General Practictioner, Cardiologist etc."
          />
          <div>
            <p className="mb-1 md:text-base text-xs">Bio</p>
            <textarea
              className="border w-full outline-none rounded-md p-2 md:text-base text-xs"
              name=""
              value={bio}
              onChange={(e) => setBio(e.target.value)}
              placeholder="Type here..."
              id=""
            ></textarea>
          </div>
          <div className="my-4 md:px-12">
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              className="bg-[#1A94EC] w-full"
            >
              {loading ? "Loading..." : "Save and Continue"}
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
