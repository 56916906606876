import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Firebase from "../../core/firebase";
import DataProvider from "../../apis";
import { Button, notification } from "antd";
import med from "../../assets/images/logohd.png";
import bg from "../../assets/images/bg2.png";
import doc from "../../assets/images/doc4.avif";
import EmailInput from "../../components/form/email-input";
import PasswordInput from "../../components/form/password-input";
import CustomInput from "../../components/form/custom-input";
import { NotificationType, firebaseSignupErrorMessage } from "../../utils";
import { useAppStore } from "../../store";

export default function Register() {
  const [api, contextHolder] = notification.useNotification();
  const { setToken, setUser } = useAppStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  async function onSubmit() {
    if (name.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Enter your full name!"
      );
    }
    if (email.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Email must not be empty, type your email address!"
      );
    }

    if (password.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Password must not be empty, type your email address!"
      );
    }
    setLoading(true);
    await Firebase.signupWithEmail(email, password)
      .then(async (result) => {
        const user = result.user;
        await Firebase.updateUserProfile(user, {
          displayName: name,
          // phoneNumber: phone,
        }).then(async () => {
          setToken({ token: await user.getIdToken() });
          DataProvider.setToken(await user.getIdToken());
          const newUsr = await DataProvider.updateProfile({
            fullname: name,
            email: email,
            // phoneNumber: phone,
          });
          setUser(newUsr.data);
          setLoading(false);
          navigate("/set-up");
        });
      })
      .catch(async (error) => {
        if (error.code === "auth/email-already-in-use") {
          await Firebase.loginWithEmail(email, password)
            .then(async (result) => {
              const user = result.user;
              DataProvider.setToken(await user.getIdToken());
              setToken({ token: await user.getIdToken() });
              const { data } = await DataProvider.getDoctorProfile();
              // const { data } = await DataProvider.getProfile();
              // if (data && data.doctor) {
              //  return openNotificationWithIcon(
              //    "warning",
              //    "Warning",
              //    "Email already registered. Kindly sign in!"
              //  );
              // }

              // setUser(data);
              // setLoading(false);
              // return navigate("/set-up");

              if (data && data?.status && data?.status === "failed") {
                const usr = await DataProvider.getProfile();
                setUser(usr.data);
                setLoading(false);
                return navigate("/set-up");
              } else {
                return openNotificationWithIcon(
                  "error",
                  "Failed",
                  "Email already registered. Kindly sign in!"
                );
              }
            })
            .catch(async (error) => {
              if (error.code === "auth/wrong-password") {
                return openNotificationWithIcon(
                  "error",
                  "Failed",
                  "Email already registered. Kindly sign in!"
                );
              }
              return openNotificationWithIcon(
                "error",
                "Failed",
                "An error occurred during sign-up, try again."
              );
            });
        }
        const errMsg: string = firebaseSignupErrorMessage(error);
        setLoading(false);
        return openNotificationWithIcon("error", "Failed", errMsg);
      });
  }

  return (
    <div className="w-full h-full">
      <div
        className="h-screen lg:hidden flex justify-center items-center w-full bg-cover bg-[#0081D4] bg-blend-overlay"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="min-h-[60%] sm:w-[60%] w-[90%] rounded bg-white flex flex-col justify-center items-center pb-4">
          <div className="w-[90%] my-4">
            <h1 className="text-2xl font-bold mb-2">Register</h1>
            <p className="text-base text-gray-500">
              Fill in the details to register your account.
            </p>
          </div>
          <div className="w-[90%]">
            <CustomInput
              onChangeText={setName}
              value={name}
              title="Full name"
              placeholder="Enter your full name"
            />
            <EmailInput
              email={email}
              setEmail={setEmail}
              placeholder="Enter your email address."
            />
            <PasswordInput
              required
              forgot
              password={password}
              setPassword={setPassword}
              placeholder="*****************"
            />
            <Button
              type="primary"
              onClick={onSubmit}
              size="large"
              className="bg-[#1A94EC] w-full"
            >
              Sign up
            </Button>

            <div className="w-full flex justify-center items-center mt-12">
              <p className="text-[#ADADAD] text-sm">
                Have an account?
                <Link to="/sign-in" className="text-[#1A94EC] mx-2">
                  Sign in
                </Link>
              </p>
            </div>
            <div className="absolute bottom-7 right-9 flex justify-center items-center">
              {/* <img src={logo} className="h-10 w-32" alt="mcare" /> */}
              <img src={med} className="h-8 mx-4" alt="" />
              {/* <img src={MTNLogo} alt="mtn" className="h-8" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen w-full lg:flex hidden justify-between items-center">
        <div
          className="h-full flex flex-col justify-center items-center w-[50%] bg-cover bg-[#0081D4] bg-blend-overlay"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="absolute top-6 left-4">
            <img src={med} className="h-8 w-28" alt="mcare" />
          </div>
          <div className="flex flex-col justify-center items-center text-center md:w-[70%]">
            <h1 className="text-2xl leading-10 text-white">
              Medpharma's Consultation
            </h1>
            <h1 className="text-2xl leading-10 text-white">
              I have trippled my income as a Specialist.
            </h1>
          </div>
          <div className="my-4 flex flex-col justify-center w-full items-center">
            <div className="h-20 w-20 rounded-full border-4 border-white">
              <img src={doc} alt="" className="h-full w-full rounded-full" />
            </div>
            <h3 className="text-white mt-2 font-medium">Dr. Edwin Mensah</h3>
            <p className="text-[#7ED0FD]">Tamale Teaching Hospital</p>
          </div>
        </div>
        <div className="h-full w-[47%] flex flex-col justify-center items-center">
          <div className="w-[80%] my-4">
            <h1 className="text-4xl font-bold mb-4">Register</h1>
            <p className="text-xl text-gray-500 ml-2">
              Fill in the details to register your account.
            </p>
          </div>
          <div className="w-[80%]">
            <CustomInput
              onChangeText={setName}
              value={name}
              title="Full name"
              placeholder="Enter your full name"
            />
            <EmailInput
              email={email}
              setEmail={setEmail}
              placeholder="Enter your email address."
            />
            <PasswordInput
              required
              forgot
              password={password}
              setPassword={setPassword}
              placeholder="*****************"
            />
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              className="bg-[#1A94EC] w-full"
            >
              {loading ? "Loading..." : "Sign up"}
            </Button>

            <div className="w-full flex justify-center items-center mt-12">
              <p className="text-[#ADADAD] text-lg">
                Have an account?
                <Link to="/sign-in" className="text-[#1A94EC] mx-2">
                  Sign in
                </Link>
              </p>
            </div>
            <div className="absolute bottom-5 right-9 flex">
              <img src={med} className="h-8 mx-4" alt="" />
              {/* <img src={MTNLogo} className="h-8" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
