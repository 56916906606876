/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import DataProvider from "../../apis";
import CustomInput from "../form/custom-input";
import PasswordInput from "../form/password-input";
import EmailInput from "../form/email-input";
import { Button, message } from "antd";
import Colors from "../../constants/Colors";
import Firebase from "../../core/firebase";
import LoadWrapper from "../elements/load-wrapper";
import UploadPicture from "./upload-picture";
import { useAppStore } from "../../store";

export default function AccountInfo(props: any) {
  const { setUser, doctor } = useAppStore();
  const { tab } = props;
  // const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [innerTab, setInnerTab] = useState<number | null>(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  // const [dob, setDob] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  // const [gender, setGender] = useState({ value: null });
  //   const [height, setHeight] = useState();
  //   const [weight, setWeight] = useState(null);
  const [bio, setBio] = useState<string>("");
  const [speciality, setSpeciality] = useState<string>("");
  const [license, setLicense] = useState<string>("");
  const [institution, setInstitution] = useState<string>("");
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  // const [data, setData] = useState<any>(null);

  const getProfile = useCallback(async () => {
    setRefreshing(true);
    const { data } = await DataProvider.getProfile();
    // setData(data);
    setUser({ ...data });
    setEmail(data.email);
    setName(data.fullname);
    setPhone(data.phoneNumber);
    // setDob(data.dateOfBirth);
    // setHeight(data.height);
    // setWeight(data.weight);
    // setGender({ value: data.gender });
    setImage(data.picture);

    if (data.doctor) {
      setBio(data.doctor.bio ? data.doctor.bio : "");
      setSpeciality(data.doctor.speciality ? data.doctor.speciality : "");
      setLicense(data.doctor.licenseId ? data.doctor.licenseId : "");
      setInstitution(data.doctor.institution ? data.doctor.institution : "");
    }

    setRefreshing(false);
  }, [setUser]);

  useEffect(() => {
    if (tab === "1" && innerTab === null) {
      getProfile();
    }
  }, [tab, getProfile, innerTab]);

  const updateProfile = async () => {
    setLoading(true);
    messageApi
      .open({
        type: "loading",
        content: "Profile updating...",
        duration: 2.5,
      })
      .then(async () => {
        try {
          const user: any = Firebase.currentUser();

          Promise.all([user]).then(async (values) => {
            if (values[0].email !== email) {
              await Firebase.updateUserEmail(email);
            }

            if (values[0].displayName !== name) {
              await Firebase.updateUserProfile(user, {
                displayName: name,
              });
            }
          });

          const payload = { email, name, phoneNumber: phone };
          const { data } = await DataProvider.updateProfile(payload);
          setUser({ ...data });
          setLoading(false);
          message.success("Profile updated successfully.");
          getProfile();
        } catch (error: any) {
          setLoading(false);
          message.error("Failed to update profile. Try again later..");
        }
      });
  };

  const updateDoctor = async () => {
    setLoadingx(true);
    messageApi
      .open({
        type: "loading",
        content: "Profile updating...",
        duration: 2.5,
      })
      .then(async () => {
        try {
          const payload = { license, institution, speciality, bio };
          if (doctor) {
            await DataProvider.updateDoctorProfile(doctor?.id, payload);
            setLoadingx(false);
            message.success("Profile updated successfully.");
            getProfile();
          }
        } catch (error) {
          setLoadingx(false);
          message.error("Failed to update profile. Try again later..");
        }
      });
  };

  return (
    <div className="bg-white w-full h-full rounded-lg border py-4 md:px-4 px-2">
      {innerTab === null ? (
        <>
          <LoadWrapper loading={refreshing}>
            <div className="">
              <div className="border-b pb-4">
                <div className="flex md:flex-row flex-col justify-start my-2">
                  <div className="md:w-72 w-full font-medium text-sm md:mb-0 mb-2">
                    <h3>Your photo</h3>
                    <p>This will be displayed on your profile.</p>
                  </div>
                  <div className="flex flex-col justify-center items-center md:ml-20">
                    <div className="shadow-md h-28 w-28 flex justify-center items-center border rounded-full">
                      <img
                        src={image}
                        alt=""
                        className="rounded-full h-24 w-24"
                      />
                    </div>
                    <div className="flex mt-5 ">
                      <Button type="link" style={{ color: Colors.deepgray }}>
                        Delete
                      </Button>
                      <Button
                        onClick={() => setInnerTab(0)}
                        type="primary"
                        style={{ background: Colors.primary }}
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3 className="">Name</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <CustomInput
                      style="py-2"
                      placeholder="Enter your full name"
                      value={name}
                      onChangeText={setName}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3 className="">Email</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <EmailInput
                      noTitle
                      email={email}
                      setEmail={setEmail}
                      placeholder="Enter your email address."
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3 className="">Contact</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <CustomInput
                      style="py-2"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChangeText={setPhone}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3>Password</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <PasswordInput
                      required
                      disable={true}
                      noTitle
                      change
                      password={password}
                      setPassword={setPassword}
                      placeholder="*****************"
                    />
                  </div>
                </div>
                <div className="">
                  <Button
                    onClick={updateProfile}
                    type="primary"
                    style={{ background: Colors.primary }}
                  >
                    {loading ? "Updating..." : "Update Information"}
                  </Button>
                </div>
              </div>
              <div className="border-b pb-4 mt-4">
                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3>License</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <CustomInput
                      style="py-2"
                      placeholder="Enter your license ID"
                      value={license}
                      onChangeText={setLicense}
                    />
                  </div>
                </div>
                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3>Institution</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <CustomInput
                      style="py-2"
                      placeholder="Enter your place of work"
                      value={institution}
                      onChangeText={setInstitution}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:w-72 w-full font-medium text-sm">
                    <h3>Speciality</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <CustomInput
                      style="py-2"
                      placeholder="Speciality"
                      value={speciality}
                      onChangeText={setSpeciality}
                    />
                  </div>
                </div>

                <div className="flex md:flex-row flex-col justify-start md:items-center my-2">
                  <div className="md:md:w-72 w-full font-medium text-sm">
                    <h3>Bio</h3>
                  </div>
                  <div className="md:ml-20 md:w-[50%] w-full">
                    <textarea
                      className="border outline-none  rounded-lg p-2 h-28 w-full"
                      name="bio"
                      id="bio"
                      placeholder="A brief about what you do..."
                      value={bio}
                      onChange={(event) => setBio(event.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="">
                  <Button
                    onClick={updateDoctor}
                    type="primary"
                    style={{ background: Colors.primary }}
                  >
                    {loadingx ? "Updating..." : "Update Doctor Information"}
                  </Button>
                </div>
              </div>
            </div>
          </LoadWrapper>
          {contextHolder}
        </>
      ) : innerTab === 0 ? (
        <UploadPicture innerTab={innerTab} setInnerTab={setInnerTab} />
      ) : null}
    </div>
  );
}
