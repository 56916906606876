import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Firebase from "../../core/firebase";
import DataProvider from "../../apis";
import { Button, notification } from "antd";
import med from "../../assets/images/logohd.png";
import bg from "../../assets/images/bg2.png";
import doc from "../../assets/images/doc4.avif";
import EmailInput from "../../components/form/email-input";
import PasswordInput from "../../components/form/password-input";
import { NotificationType, firebaseSigninErrorMessage } from "../../utils";
import { useAppStore } from "../../store";

export default function Login() {
  const [api, contextHolder] = notification.useNotification();
  const { setDoctor, setToken } = useAppStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const openNotificationWithIcon = (
    type: NotificationType,
    title: string,
    message: string
  ) => {
    api[type]({
      message: title,
      description: message,
    });
  };

  async function onSubmit() {
    if (email.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Email must not be empty, type your email address!"
      );
    }

    if (password.length < 5) {
      return openNotificationWithIcon(
        "warning",
        "Warning",
        "Password must not be empty, type your email address!"
      );
    }

    setLoading(true);
    await Firebase.loginWithEmail(email, password)
      .then(async (result) => {
        const user = result.user;
        DataProvider.setToken(await user.getIdToken());
        const { data } = await DataProvider.getDoctorProfile();
        if (data && data?.status && data?.status === "failed") {
          setLoading(false);
          return openNotificationWithIcon(
            "error",
            "Failed",
            "Account not registered as a Doctor. Sign up!"
          );
        }

        setDoctor(data);
        setToken({ token: await user.getIdToken() });
        setLoading(false);
        navigate("/");
      })
      .catch(async (e) => {
        const err: string = firebaseSigninErrorMessage(e);
        setLoading(false);
        return openNotificationWithIcon("error", "Failed", err);
      });
  }

  return (
    <div className="w-full h-full">
      <div
        className="h-screen lg:hidden flex justify-center items-center w-full bg-cover bg-[#0081D4] bg-blend-overlay"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="min-h-[60%] sm:w-[60%] w-[90%] rounded bg-white flex flex-col justify-center items-center pb-4">
          <div className="w-[90%] my-4">
            <h1 className="text-2xl font-bold mb-1">Login</h1>
            <p className="text-base text-gray-500">Sign in to your account.</p>
          </div>
          <div className="w-[90%]">
            <EmailInput
              email={email}
              setEmail={setEmail}
              placeholder="Enter your email address."
            />
            <PasswordInput
              required
              forgot
              password={password}
              setPassword={setPassword}
              placeholder="*****************"
            />
            <Button
              type="primary"
              onClick={onSubmit}
              size="large"
              className="bg-[#1A94EC] w-full"
            >
              Sign in
            </Button>

            <div className="w-full flex justify-center items-center mt-12">
              <p className="text-[#ADADAD] text-base">
                Don’t have an account?{" "}
                <Link to="/sign-up" className="text-[#1A94EC]">
                  Create an account
                </Link>
              </p>
            </div>
            <div className="absolute bottom-7 right-5 flex justify-center items-center">
              {/* <img src={logo} className="h-10 w-32" alt="mcare" /> */}
              <img src={med} className="h-8 mx-4" alt="" />
              {/* <img src={MTNLogo} alt="mtn" className="h-8" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="h-screen w-full lg:flex hidden justify-between items-center">
        <div
          className="h-full flex flex-col justify-center items-center w-[50%] bg-cover bg-[#0081D4] bg-blend-overlay"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <div className="absolute top-6 left-4">
            <img src={med} className="h-8 w-28" alt="mcare" />
          </div>
          <div className="flex flex-col justify-center items-center text-center md:w-[70%]">
            <h1 className="text-2xl leading-10 text-white">
              Medpharma's Consultation
            </h1>
            <h1 className="text-2xl leading-10 text-white">
              I have trippled my income as a Specialist.
            </h1>
          </div>
          <div className="my-4 flex flex-col justify-center w-full items-center">
            <div className="h-20 w-20 rounded-full border-4 border-white">
              <img src={doc} alt="" className="h-full w-full rounded-full" />
            </div>
            <h3 className="text-white mt-2 font-medium">Dr. Edwin Mensah</h3>
            <p className="text-[#7ED0FD]">Tamale Teaching Hospital</p>
          </div>
        </div>
        <div className="h-full w-[47%] flex flex-col justify-center items-center">
          <div className="w-[80%] my-4">
            <h1 className="text-6xl font-bold mb-4">Login</h1>
            <p className="text-xl text-gray-500 ml-2">
              Sign in to your account.
            </p>
          </div>
          <div className="w-[80%]">
            <EmailInput
              email={email}
              setEmail={setEmail}
              placeholder="Enter your email address."
            />
            <PasswordInput
              required
              forgot
              password={password}
              setPassword={setPassword}
              placeholder="*****************"
            />
            <Button
              type="primary"
              size="large"
              onClick={onSubmit}
              className="bg-[#1A94EC] w-full"
            >
              {loading ? "Loading..." : "Sign in"}
            </Button>
            <div className="w-full flex justify-center items-center mt-12">
              <p className="text-[#ADADAD] text-lg">
                Don’t have an account?
                <Link to="/sign-up" className="text-[#1A94EC] mx-2">
                  Create an account
                </Link>
              </p>
            </div>
            <div className="absolute bottom-5 right-9 flex">
              <img src={med} className="h-8 mx-4" alt="" />
              {/* <img src={MTNLogo} className="h-8" alt="" /> */}
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
