import React, { ReactNode, useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import { Layout } from "antd";

type Props = {
  children: ReactNode;
};
export default function MainLayout({ children }: Props) {
  const { Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout className="h-screen flex lg:justify-between justify-center">
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Content
        className={` ${
          collapsed ? "w-full" : "lg:w-[80%]"
        } flex flex-col w-full h-full bg-gray-50`}
      >
        <div className="h-24 fixed z-50 w-full border-b border-gray-300 border-solid">
          <Header />
        </div>
        <div className="flex-1 overflow-y-auto md:p-4 p-2 mt-20">
          {children}
        </div>
      </Content>
    </Layout>
  );
}
