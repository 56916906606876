import React from "react";
import { Collapse, Image } from "antd";
import moment from "moment";
import Empty from "../elements/empty";

const { Panel } = Collapse;

export default function PatientRecords(props: any) {
  const { data } = props;
  return (
    <div>
      <Collapse accordion expandIconPosition="end">
        <Panel header="Patient Medical Records" key={0}>
          {data && data.length > 0 ? (
            <>
              {data.map((item: any, idx: any) => (
                <Collapse accordion expandIconPosition="end" key={idx}>
                  <Panel
                    header={moment(item.date).format("Do MMMM YYYY")}
                    key={item.id}
                  >
                    <div className="w-full bg-white">
                      <div className="flex justify-center w-full">
                        <div className="flex flex-col w-full">
                          <div className="w-full">
                            <div className="w-full">
                              {item.prescription && (
                                <div className="mb-4">
                                  <h3 className="font-semibold">
                                    Prescription
                                  </h3>
                                  <Image
                                    width={100}
                                    src={item.prescription}
                                    alt="prescription"
                                  />
                                </div>
                              )}
                              <table className="divide-y divide-gray-300 w-full">
                                <tbody className="bg-white divide-y divide-gray-300">
                                  <tr className="whitespace-nowrap">
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-900">
                                        Doctor
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-500">
                                        {item.doctorName}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="whitespace-nowrap">
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-900">
                                        Diagnosis
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-500">
                                        {item.diagnosis
                                          ? item.diagnosis
                                          : "n/a"}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="whitespace-nowrap">
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-900">
                                        Recommendations
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-500 capitalize">
                                        {item.recommendations
                                          ? item.recommendations
                                          : "n/a"}
                                      </div>
                                    </td>
                                  </tr>
                                  <tr className="whitespace-nowrap">
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-900">
                                        Remarks
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 border-r border">
                                      <div className="text-sm text-gray-500">
                                        {item.remarks ? item.remarks : "n/a"}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              ))}
            </>
          ) : (
            <Empty
              title="Medical Records"
              imageStyle="h-16"
              titleStyle="text-xs"
              description="No medical record available"
            />
          )}
        </Panel>
      </Collapse>
    </div>
  );
}
