/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

type Props = {
  date: string;
  startTime: string;
  endTime: string;
  onCountdownEnd?: (id: any) => void;
  params?: string;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppointmentCountdown = ({
  date,
  startTime,
  endTime,
  params,
  setStatus,
  onCountdownEnd,
}: Props) => {
  const parseTime = (time: string) => {
    const [hour, minute] = time
      .replace(/[^0-9:]/g, "")
      .split(":")
      .map(Number);
    const isPM = time.toLowerCase().includes("pm");
    return { hour: isPM && hour < 12 ? hour + 12 : hour, minute };
  };

  const parseDate = (dateStr: string) => {
    const [day, month, year] = dateStr.split("-").map(Number);
    return new Date(year, month - 1, day);
  };

  const calculateTimeLeft = () => {
    const currentDate = new Date();
    const targetDate = parseDate(date);
    const { hour: startHour, minute: startMinute } = parseTime(startTime);
    targetDate.setHours(startHour, startMinute);

    const endDate = parseDate(date);
    const { hour: endHour, minute: endMinute } = parseTime(endTime);
    endDate.setHours(endHour, endMinute);

    if (currentDate >= endDate) {
      if (typeof onCountdownEnd === "function") {
        onCountdownEnd(params);
      }
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const difference = targetDate.getTime() - currentDate.getTime();
    const totalSeconds = Math.max(difference / 1000, 0); // Ensure non-negative value

    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return { days, hours, minutes, seconds };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);

      if (
        newTimeLeft.days === 0 &&
        newTimeLeft.hours === 0 &&
        newTimeLeft.minutes === 0 &&
        newTimeLeft.seconds === 0
      ) {
        setStatus(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []); // Empty dependency array means this effect runs only once

  const formatTime = (time: number) => {
    return time < 10 ? `0${time}` : `${time}`;
  };

  return (
    <div className="bg-[#06509E] p-1 rounded-lg text-white w-full grid grid-cols-4 gap-2 justify-center">
      <div className="flex flex-col justify-center items-center">
        <h4 className="mb-1 font-semibold">Days</h4>
        {timeLeft.days > 0 ? (
          <span>{`${formatTime(timeLeft.days)}`}</span>
        ) : (
          <span>00</span>
        )}
      </div>
      <div className="flex flex-col justify-center items-center">
        <h4 className="mb-1 font-semibold">Hours</h4>
        <span>{`${formatTime(timeLeft.hours)}`}</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h4 className="mb-1 font-semibold">Minutes</h4>
        <span>{`${formatTime(timeLeft.minutes)}`}</span>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h4 className="mb-1 font-semibold">Seconds</h4>
        <span>{`${formatTime(timeLeft.seconds)}`}</span>
      </div>
    </div>
  );
};

export default AppointmentCountdown;
