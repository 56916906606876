import { Button, Upload, UploadProps, message } from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import DataProvider from "../../apis";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";

export default function ProfileImageSetup(props: any) {
  const { doctor, setDoctor } = useAppStore();
  const navigate = useNavigate();
  // const { tab, setTab } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loadingx, setLoadingx] = useState<boolean>(false);

  useEffect(() => {
    if (!doctor) navigate("/signin");
  }, [doctor, navigate]);

  const handleChange: UploadProps["onChange"] = (info) => {};

  const uploadButton = (
    <button
      className="rounded-full flex flex-col h-full w-full text-[#00A2F8] justify-center items-center"
      style={{ border: 0, background: "none" }}
      type="button"
    >
      {loading ? <LoadingOutlined size={32} /> : <PlusOutlined size={32} />}
      <div style={{ marginTop: 8 }} className="text-[#00A2F8] font-semibold">
        Upload
      </div>
    </button>
  );

  const customRequest = async (options: any) => {
    const formData = new FormData();
    formData.append("file", options.file);

    try {
      setLoading(true);
      const result = await DataProvider.cloudUpload(formData);
      setImageUrl(result.data.url);

      // Handle the result and call onSuccess
      options.onSuccess(result.data, options.file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors and call onError
      options.onError(error, options.file);
    }
  };

  async function onSaveImage() {
    if (doctor) {
      setLoadingx(true);
      messageApi
        .open({
          type: "loading",
          content: "Profile updating...",
          duration: 2.5,
        })
        .then(async () => {
          try {
            const payload = { profile_image: imageUrl };
            await DataProvider.updateDoctorProfile(doctor?.id, payload);
            await DataProvider.updateProfile(payload);

            const { data } = await DataProvider.getDoctorProfile();
            setDoctor(data);
            setLoading(false);
            message.success("Profile updated successfully.");
            navigate("/");
          } catch (error: any) {
            setLoading(false);
            message.error("Failed to update profile. Try again..");
          }
        });
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center md:my-8 px-4">
      <div className="lg:w-[50%] md:w-[70%] w-full flex flex-col justify-center items-center">
        <div className="mb-4">
          <h1 className="md:text-3xl text-xl font-bold">
            Set Up Profile Image
          </h1>
          <p className=" md:text-base text-sm text-gray-500">
            Click on upload button below to upload your profile image.
          </p>
        </div>

        <div className="my-4 w-full flex flex-col justify-center items-center">
          <div className="h-36 w-36 rounded-full shadow-lg p-3">
            <div className="h-full w-full rounded-full bg-[#E0F4FF] cursor-pointer flex justify-center items-center">
              <Upload
                name="avatar"
                // listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={customRequest}
                //   beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    className="rounded-full"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </div>
          </div>

          <div className="flex flex-col mt-8 md:w-[50%] w-full">
            {imageUrl === null ? (
              <Button size="large" disabled type="primary" className="">
                Save Image
              </Button>
            ) : (
              <Button
                size="large"
                onClick={onSaveImage}
                type="primary"
                style={{ background: Colors.primary }}
                className="mx-4"
              >
                {loadingx ? "Saving..." : "Save Image"}
              </Button>
            )}
            <Button onClick={() => navigate("/")} size="large" className="my-4">
              Skip
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
