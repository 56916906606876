import React from "react";
import { Button, Result } from "antd";
import Colors from "../constants/Colors";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="bg-white flex justify-center items-center h-screen w-full">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={[
          <Button
            onClick={() => navigate("/")}
            type="primary"
            style={{ backgroundColor: Colors.primary }}
          >
            Back Home
          </Button>,
          <Button onClick={() => navigate("/sign-in")}>Sign in</Button>,
        ]}
      />
    </div>
  );
}
