// /* eslint-disable @typescript-eslint/no-unused-vars */
// import React, { useCallback, useEffect, useState } from "react";
// import {
//   ConsoleLogger,
//   DefaultDeviceController,
//   DefaultMeetingSession,
//   DeviceChangeObserver,
//   LogLevel,
//   MeetingSessionConfiguration,
//   MeetingSessionStatusCode,
// } from "amazon-chime-sdk-js";
// import { useLocation, useNavigate } from "react-router-dom";
// import VideoLocalOutput from "../../components/elements/video-local-output";
// import VideoRemoteOutput from "../../components/elements/video-remote-output";
// import Controls from "../../components/elements/controls";
// import ChatComponent from "../../components/elements/chat-component";
// import { message } from "antd";
// import { useMeetingState } from "../../context/meeting-provider";

// export default function VideoMeeting() {
//   const [messageApi, contextHolder] = message.useMessage();
//   const navigate = useNavigate();
//   const {
//     api,
//     meetingSession,
//     setStartedMediaInputs,
//     camView,
//     hasStartedMediaInputs,
//     isCameraOn,
//     setCamView,
//     setIsCameraOn,
//     setShowChat,
//     showChat,
//   } = useMeetingState();

//   useEffect(() => {
//     if (!meetingSession) {
//       return;
//     }

//     const setupInput = async ({
//       audioId,
//       videoId,
//     }: {
//       audioId: string;
//       videoId: string;
//     }) => {
//       if (!audioId || !videoId) {
//         message.error("No video nor audio input detected.");
//         return;
//       }

//       const audioInputDevices =
//         await meetingSession.audioVideo.listAudioInputDevices();
//       console.log("Audio Input Devices:", audioInputDevices);
//       if (audioInputDevices.length) {
//         await meetingSession.audioVideo.startAudioInput(audioId);
//       } else {
//         message.error("No audio input devices found.");
//       }

//       const videoInputDevices =
//         await meetingSession.audioVideo.listVideoInputDevices();
//       console.log("Video Input Devices:", videoInputDevices);
//       if (videoInputDevices.length) {
//         const defaultVideoId = videoInputDevices[0].deviceId;
//         await meetingSession.audioVideo.startVideoInput(
//           videoId === "default" ? defaultVideoId : videoId
//         );
//       } else {
//         message.error("No video input devices found.");
//       }

//       setStartedMediaInputs(true);
//     };

//     const setupOutput = async () => {
//       const audioOutputDevices =
//         await meetingSession.audioVideo.listAudioOutputDevices();
//       console.log("Audio Output Devices:", audioOutputDevices);
//       if (audioOutputDevices.length) {
//         const defaultAudioOutputId = audioOutputDevices[0].deviceId;
//         await meetingSession.audioVideo.chooseAudioOutput(defaultAudioOutputId);
//       } else {
//         message.error("No audio output devices found.");
//       }
//     };

//     const addObservers = async () => {
//       const connectionObserver = {
//         connectionDidBecomePoor: () => {
//           message.warning("Your connection is poor");
//         },
//         connectionDidSuggestStopVideo: () => {
//           message.info("Recommend turning off your video");
//         },
//         videoSendDidBecomeUnavailable: () => {
//           message.warning("You cannot share your video");
//         },
//         videoAvailabilityDidChange: (videoAvailability: any) => {
//           if (videoAvailability.canStartLocalVideo) {
//             message.info("You can share your video");
//           } else {
//             message.warning("You cannot share your video");
//           }
//         },
//       };

//       const sessionObserver = {
//         audioVideoDidStop: (sessionStatus: any) => {
//           const sessionStatusCode = sessionStatus.statusCode();
//           if (sessionStatusCode === MeetingSessionStatusCode.MeetingEnded) {
//             messageApi.open({
//               type: "info",
//               content: "This meeting session has ended.",
//               duration: 2,
//             });
//           }
//         },
//         messagingSessionDidStart: () => {
//           message.success("Meeting session started");
//         },
//         messagingSessionDidStartConnecting: (reconnecting: any) => {
//           if (reconnecting) {
//             messageApi.open({
//               type: "loading",
//               content: "Start reconnecting...",
//             });
//           } else {
//             messageApi.open({
//               type: "loading",
//               content: "Start reconnecting...",
//             });
//           }
//         },
//         messagingSessionDidStop: (event: any) => {
//           message.info(`Closed: ${event.code} ${event.reason}`);
//         },
//         messagingSessionDidReceiveMessage: (msg: any) => {
//           message.info(`Receive message type ${msg.type}`);
//         },
//       };

//       const audioInputMuteObserver: DeviceChangeObserver = {
//         audioInputMuteStateChanged: (device: any, muted: any) => {
//           message.info(
//             `Device ${muted ? "is muted in hardware." : "is not unmuted."}`
//           );
//         },
//         audioInputsChanged: (freshAudioInputDeviceList) => {
//           if (freshAudioInputDeviceList) {
//             freshAudioInputDeviceList.forEach((mediaDeviceInfo) => {
//               console.log(
//                 `Device ID: ${mediaDeviceInfo.deviceId} Microphone: ${mediaDeviceInfo.label}`
//               );
//             });
//           }
//         },
//         audioOutputsChanged: (freshAudioOutputDeviceList) => {
//           if (freshAudioOutputDeviceList) {
//             console.log("Audio outputs updated: ", freshAudioOutputDeviceList);
//           }
//         },
//       };

//       meetingSession.audioVideo.addObserver(connectionObserver);
//       meetingSession.audioVideo.addObserver(sessionObserver);
//       meetingSession.audioVideo.addDeviceChangeObserver(audioInputMuteObserver);
//     };

//     setupInput({ audioId: "default", videoId: "default" }).then(() => {
//       setupOutput();
//       addObservers();
//       meetingSession.audioVideo.start();
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [meetingSession]);

//   return (
//     <div className="m-0 p-0">
//       <div className="flex justify-center w-full h-screen bg-black p-2">
//         <div className="w-full h-full flex flex-col justify-start items-start bg-black">
//           {meetingSession && hasStartedMediaInputs && (
//             <div className="w-full h-full">
//               <div className="flex w-full h-[85%] my-4">
//                 <div
//                   className={`bg-gray-700 h-full w-full ${
//                     camView ? "" : "hidden"
//                   }`}
//                 >
//                   <VideoRemoteOutput meetingSession={meetingSession} />
//                 </div>
//                 <div
//                   className={`bg-gray-700 h-full w-full ${
//                     camView ? "hidden" : ""
//                   }`}
//                 >
//                   <VideoLocalOutput meetingSession={meetingSession} />
//                 </div>
//                 <div
//                   onClick={() => setCamView(!camView)}
//                   className={`${
//                     camView ? "" : "hidden"
//                   } bg-gray-700 border-2 border-solid border-gray-200 rounded md:h-60 md:w-52 h-56 w-48 absolute md:bottom-10 md:right-8 bottom-32 right-4`}
//                 >
//                   <VideoLocalOutput meetingSession={meetingSession} />
//                 </div>
//                 <div
//                   onClick={() => setCamView(!camView)}
//                   className={`${
//                     camView ? "hidden" : ""
//                   } bg-gray-700 border-2 border-solid border-gray-200 rounded md:h-60 md:w-52 h-56 w-48 absolute md:bottom-10 md:right-8 bottom-32 right-4`}
//                 >
//                   <VideoRemoteOutput meetingSession={meetingSession} />
//                 </div>
//               </div>
//               <div className="h-12 w-full flex justify-center items-center text-white">
//                 <Controls
//                   isCameraOn={isCameraOn}
//                   setIsCameraOn={setIsCameraOn}
//                   meetingSession={meetingSession}
//                   setShowChat={setShowChat}
//                   showChat={showChat}
//                 />
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//       {showChat && (
//         <div className="fixed bottom-0 right-0 md:h-[500px] h-[300px] w-[400px] bg-white shadow-lg border border-gray-300 rounded-lg">
//           <ChatComponent
//             meetingSession={meetingSession}
//             setShowChat={setShowChat}
//             showChat={showChat}
//           />
//         </div>
//       )}
//       {contextHolder}
//     </div>
//   );
// }

import React from "react";

export default function Videomeeting() {
  return <div></div>;
}
