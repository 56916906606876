// import { Image } from "antd";
import moment from "moment";
import React from "react";

export default function PatientInfo(props: any) {
  const { data } = props;
  return (
    <div className="w-full bg-white">
      <div className="flex justify-center w-full">
        <div className="flex flex-col w-full">
          <div className="w-full">
            <div className="w-full">
              {/* {data.picture && (
                <div className="mb-4">
                  <Image width={120} src={data.picture} alt="patient" />
                </div>
              )} */}
              <table className="divide-y divide-gray-300 w-full">
                <tbody className="bg-white divide-y divide-gray-300">
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Name</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.fullname}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Birth Date</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.dateOfBirth
                          ? moment(data.dateOfBirth).format("Do MMMM YYYY")
                          : "n/a"}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Gender</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500 capitalize">
                        {data.gender ? data.gender : "n/a"}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Height</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.height ? data.height : "n/a"}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Weight</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.weight ? data.weight : "n/a"}
                      </div>
                    </td>
                  </tr>
                  <tr className="whitespace-nowrap">
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-900">Blood Group</div>
                    </td>
                    <td className="px-6 py-4 border-r border">
                      <div className="text-sm text-gray-500">
                        {data.bloodGroup ? data.bloodGroup : "n/a"}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
