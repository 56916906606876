import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { router } from "./routes/routes";
import { ConfigProvider } from "antd";
import { AxiosInterceptor } from "./core/instance";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

const rootContainer = document.getElementById("root") as HTMLElement;
let container;

container = document.createElement("div");
container.id = "amazon-chime-sdk-widget-container";
rootContainer.appendChild(container);

const root = ReactDOM.createRoot(rootContainer);

root.render(
  <React.StrictMode>
    <AxiosInterceptor>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0081D4",
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </AxiosInterceptor>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// v=spf1 +a +mx +ip4:162.254.39.91 +ip4:162.254.39.97 include:spf.web-hosting.com +ip4:162.254.39.95 ~all
