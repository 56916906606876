// import instance from "../core/provider";

import instance from "../core/instance";

const DataProvider = {
  forgotpassword: async (payload: any) => {
    return await instance.post("/user-forgot-password", payload);
  },
  verifypasswordotp: async (id: string) => {
    return await instance.get(`/user-forgot-password/verify?token=${id}`);
  },
  resetPassword: async (payload: any) => {
    return await instance.post("/user-forgot-password/update", payload);
  },
  getData: async (page: number, size: number) => {
    return await instance.get(
      `/prescriptions/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getOrder: async (id: string) => {
    return await instance.get(`/prescriptions/${id}`);
  },
  getPartners: async (filters = null) => {
    return await instance.get(`/partners?${filters}`);
  },
  setToken: async (token: string) => {
    instance.defaults.headers.common["firebase-token"] = token;
    instance.defaults.headers.get["firebase-token"] = token;
  },
  cancelOrder: async (data: any) => {
    return await instance.patch(`/prescriptions/${data.id}/cancel`, {
      reason: data.reason,
    });
  },
  addInsurance: async (data: any) => {
    return await instance.post("/me/insurance", data);
  },
  getInsurance: async () => {
    return await instance.get("/me/insurance");
  },
  deleteInsurance: async (id: string) => {
    return await instance.delete(`/me/insurance/${id}`);
  },
  getDelivery: async () => {
    return await instance.get("/me/address");
  },
  deleteDelivery: async (id: string) => {
    return await instance.delete(`/me/address/${id}`);
  },
  addAddress: async (data: any) => {
    return await instance.post("/me/address", data);
  },
  requestS3: async (data: any) => {
    return await instance.post("/upload", data);
  },
  cloudUpload: async (data: any) => {
    return await instance.post("/upload/img", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  submitPrescription: async  (data: any) => {
    return await instance.post("/prescriptions", data);
  },
  deliveryMethods: async  () => {
    return await instance.get("/delivery-methods");
  },
  doctorPrescription: async  (data: any) => {
    return await instance.post("/doctors/prescriptions", data);
  },
  getProfile:async () => {
    return await instance.get("/auth/me");
  },
  getDoctorProfile: async () => {
    return await instance.get("/doctors/me");
  },
  updateProfile: async (data: any) => {
    return await instance.patch("/auth/me", data);
  },
  registerToken: async (payload: any) => {
    return await instance.post("/notifications", payload);
  },
  getNotifications: async (filter = false) => {
    return await instance.get("/notifications/messages?readAll=" + filter);
  },
  markNotificationAsRead: async (id: string) => {
    return await instance.patch(`/notifications/messages/${id}/read`);
  },
  submitConsultation: async (payload: any) => {
    return await instance.post("/consultations", payload);
  },
  getConsultations: async () => {
    return await instance.get("/consultations");
  },
  getConsultationPrices: async () => {
    return await instance.get("/consultations-prices");
  },
  getStaffData: async () => {
    return await instance.get("/partner-staff");
  },
  addStaffData: async (payload: any) => {
    return await instance.post("/partner-staff", payload);
  },
  updateStaffData: async (id: string, payload: any) => {
    return await instance.patch(`/partner-staff/${id}`, payload);
  },
  verifystaffData: async (email: string) => {
    return await instance.patch(`/partner-staff/verify/${email}`);
  },
  sendVerificationEmail: async (payload: any) => {
    return await instance.post("/verify", payload);
  },
  verifyStaffEmail: async (id: string) => {
    return await instance.get(`/verify/${id}`);
  },
  submitDiagnostic: async (payload: any) => {
    return await instance.post("/diagnostics", payload);
  },
  getDiagnostics: async (page: number, size: number) => {
    return await instance.get(
      `/diagnostics/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getDiagnosticOrder: async (id: string) => {
    return await instance.get(`/diagnostics/${id}`);
  },
  getDiagnosticPrices: async () => {
    return await instance.get("/diagnostic-prices");
  },

  getMedicalRecords: async (page: number, size: number) => {
    return await instance.get(
      `/medical-records/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getDoctors: async (page: number, size: number) => {
    return await instance.get(
      `/doctors/query?pageNumber=${page}&pageSize=${size}`
    );
  },

  getDoctorSchedules: async (doctorId: number) => {
    return await instance.get(`/schedule/${doctorId}`);
  },
  createAppointment: async (payload: any) => {
    return await instance.post("/appointments", payload);
  },
  getUpcomingAppointment: async () => {
    return await instance.get("/appointments");
  },
  getAllAppointment: async (page: number, size: number) => {
    return await instance.get(
      `/appointments/query?pageNumber=${page}&pageSize=${size}`
    );
  },
  getSingleAppointment: async (apptId: string) => {
    return await instance.get(`/appointments/${apptId}`);
  },

  createReport: async (appointmentId: string, payload: any) => {
    return await instance.post(`/appointments/report/${appointmentId}`, payload);
  },
  updateReport: async (appointmentId: string, payload: any) => {
    return await instance.patch(`/appointments/report/${appointmentId}`, payload);
  },
  getReport: async (appointmentId: string) => {
    return await instance.get(`/appointments/report/${appointmentId}`);
  },

  getUserReports: async (userId: string) => {
    return await instance.get(`/appointments/reports/${userId}`);
  },

  getLabCeneters: async (page?: number, size?: number) => {
    return await instance.get(
      `/labs/query?pageNumber=${page}&pageSize=${size}`
    );
  },

  payDiagnosticOrder: async (id: number, payload: any) => {
    return await instance.post(`/payments/diagnostic/${id}`, payload);
  },

  sendOTp: async (otp: number, reference: any) => {
    return await instance.get(`/payments/send-otp/${otp}/${reference}`);
  },

  //Doctor Services
    getDoctorServices: async (doctorId?: number) => {
    return await instance.get(`/doctors/service/${doctorId}`);
  },

  onboardDoctor: async (payload: any)=>{
    return await instance.post("/doctors", payload);
  },
  updateDoctorProfile: async (doctorId: number, data: any) => {
    return await instance.patch(`/doctors/${doctorId}`, data);
  },

  getHistory: async (doctorId: number, page: number, size: number) => {
    return await instance.get(
      `/history/query?doctorId=${doctorId}&pageNumber=${page}&pageSize=${size}`
    );
  },
  getPayouts: async (doctorId: number) => {
    return await instance.get(`/withdrawals/${doctorId}`);
  },
  disableTimeframe: async (scheduleId: number, timeFrameId: number) => {
    return await instance.get(`/schedule/disable/${scheduleId}/${timeFrameId}`);
  },
  
  saveSchedule: async(payload: any) => {
  return await instance.post("/schedule", payload);
  },
    getDailyMeeting: async (appointmentId: number)=> {
    return await instance.get(`/daily/room/${appointmentId}`)
  }
};

export default DataProvider;
