import React, { ReactNode } from "react";
import { Spin } from "antd";

type Props = {
  children: ReactNode;
  loading: boolean;
  size?: "large" | "small" | "default" | undefined;
};

export default function LoadWrapper({ children, loading, size }: Props) {
  return (
    <Spin
      size={size ? size : "large"}
      tip={size ? null : "Loading..."}
      spinning={loading}
      className="w-full h-full"
    >
      {children}
    </Spin>
  );
}
