import React, { useEffect, useState } from "react";
import DataProvider from "../../apis";
import moment from "moment";
import ApptCalendar from "../../components/card/appt-calendar";
import RecentAppts from "../../components/card/recent-appts";
import AppointmentList from "../../components/sections/appointment-list";
import LoadWrapper from "../../components/elements/load-wrapper";

export default function Appointments() {
  const [date, setDate] = useState<Date>(new Date());
  const [data, setData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);

  async function getAllAppt(params?: Date) {
    setLoading(true);
    try {
      const results = await DataProvider.getAllAppointment(1, 200);
      const filter = results.data.appointments.filter(
        (item: any) =>
          moment(item.date, "DD-MM-YYYY").format("MMM DD YYYY") ===
          moment(params).format("MMM DD YYYY")
      );
      setData(filter);
      // const arr = results.data.appointments;
      // const res = [...arr].slice(-3);
      // setData(res);
      // console.log(results.data.appointments);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (date) {
      getAllAppt(date);
    }
  }, [date]);

  return (
    <div className="pt-4">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 rounded bg-white py-4 border">
        <ApptCalendar setDate={setDate} date={date} />
        <LoadWrapper loading={loading}>
          <RecentAppts
            setDate={setDate}
            date={date}
            data={data}
            loading={loading}
          />
        </LoadWrapper>
      </div>
      <AppointmentList />
    </div>
  );
}
