/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CaretDownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CalendarFilled,
  LogoutOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import med from "../../assets/images/logo.png";
import img from "../../assets/images/pattern_bg.png";
import { FaMoneyBill, FaUserFriends } from "react-icons/fa";
import { useAppStore } from "../../store";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type Props = {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
};
export default function Sidebar({ collapsed, setCollapsed }: Props) {
  const { logOut } = useAppStore();
  const navigate = useNavigate();
  const { Sider } = Layout;

  function onLogout() {
    logOut();
  }

  const items: MenuItem[] = [
    // getItem(<NavLink to="/">Home</NavLink>, "1", <HomeFilled />),
    getItem(<NavLink to="/">Appointments</NavLink>, "2", <CalendarFilled />),
    getItem(
      <NavLink to="/schedule">Availability</NavLink>,
      "6",
      <ClockCircleOutlined size={20} />
    ),
    getItem(
      <NavLink to="/transactions">Transactions</NavLink>,
      "3",
      <FaMoneyBill size={20} />
    ),
    getItem(
      <NavLink to="/profile/1">Profile</NavLink>,
      "4",
      <FaUserFriends size={20} />
    ),
    getItem(
      <div onClick={onLogout}>Logout</div>,
      "5",
      <LogoutOutlined size={20} />
    ),
  ];

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  return (
    <div
      className="col-span-1 lg:flex lg:flex-col justify-between items-center  hidden shadow-lg relative top-0 left-0 overflow-y-auto shadow-gray-100 h-full pt-4 bg-cover bg-center"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div>
        <div
          className={`h-12 flex px-5 items-center mb-4 ${
            collapsed ? " justify-center" : " justify-between"
          }`}
        >
          <div className={`${collapsed ? "hidden" : ""} text-xl`}>
            <img src={med} alt="" className="h-8" />
          </div>
          <div className=" cursor-pointer text-2xl" onClick={toggleCollapsed}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </div>
        </div>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          width="220px"
          theme="light"
          className="h-full flex flex-col justify-between"
          style={{
            overflow: "auto",
            height: "100%",
            position: "sticky",
            top: 0,
            left: 0,
            background: " transparent",
            scrollbarGutter: "auto",
            scrollbarWidth: "thin",
            // display: "flex"
          }}
        >
          <Menu
            defaultSelectedKeys={["1"]}
            // defaultOpenKeys={['sub1']}
            mode="inline"
            theme="light"
            // inlineCollapsed={collapsed}
            items={items}
            className="menu bg-transparent"
            expandIcon={collapsed ? "" : <CaretDownOutlined />}
            overflowedIndicator={<CaretDownOutlined />}
          />
        </Sider>
      </div>
    </div>
  );
}
