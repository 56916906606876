import React, { useState } from "react";
import { Button, Drawer } from "antd";
import { FaBell, FaMoneyBill, FaUserFriends } from "react-icons/fa";
// import {
//   MODAL_TYPES,
//   useGlobalModalContext,
// } from "../../context/global-modal-context";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";
import {
  CaretDownOutlined,
  MenuUnfoldOutlined,
  CalendarFilled,
  LogoutOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { useAppStore } from "../../store";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export default function Header() {
  const { doctor, logOut } = useAppStore();
  const { Sider } = Layout;
  const [open, setOpen] = useState(false);
  //   const { showModal } = useGlobalModalContext();
  //   const openUserModal = () => {
  //     showModal(MODAL_TYPES.NOTIFICATION_MODAL, {
  //       title: "Notifications",
  //       confirmBtn: "Ok",
  //     });
  //   };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  function onLogout() {
    logOut();
  }

  const items: MenuItem[] = [
    // getItem(<NavLink to="/">Home</NavLink>, "1", <HomeFilled />),
    getItem(<NavLink to="/">Appointments</NavLink>, "2", <CalendarFilled />),
    getItem(
      <NavLink to="/schedule">Availability</NavLink>,
      "6",
      <ClockCircleOutlined size={20} />
    ),
    getItem(
      <NavLink to="/transactions">Transactions</NavLink>,
      "3",
      <FaMoneyBill size={20} />
    ),
    getItem(
      <NavLink to="/profile/1">Profile</NavLink>,
      "4",
      <FaUserFriends size={20} />
    ),
    getItem(
      <div onClick={onLogout}>Logout</div>,
      "5",
      <LogoutOutlined size={20} />
    ),
  ];

  return (
    <div className="flex justify-between items-center bg-white h-full w-full lg:px-8 px-4">
      <div>
        <h2 className="lg:text-3xl sm:text-base text-sm flex font-semibold">
          Welcome back {doctor && doctor?.user ? doctor?.user.fullname : ""}
        </h2>
        <p className="lg:leading-9 md:text-base text-xs text-gray-500">
          How can we help you stay healthy today.
        </p>
      </div>
      <div className="lg:block hidden">
        <Button
          //   onClick={openUserModal}
          className="flex justify-evenly items-center "
        >
          <FaBell className="mx-2" />
          Notification
        </Button>
      </div>
      <div className="lg:hidden block ">
        <Button
          onClick={showDrawer}
          className="text-base flex justify-center items-center"
        >
          <MenuUnfoldOutlined />
        </Button>
      </div>
      <Drawer
        title="Medpharma"
        width={300}
        className="w-[60%]"
        onClose={onClose}
        open={open}
      >
        <Sider
          trigger={null}
          collapsible
          theme="light"
          className="h-full w-full flex flex-col justify-between"
        >
          <Menu
            mode="inline"
            theme="light"
            items={items}
            className="menu bg-transparent"
            overflowedIndicator={<CaretDownOutlined />}
          />
        </Sider>
      </Drawer>
    </div>
  );
}
