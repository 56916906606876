import {
  applyActionCode,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateCurrentUser,
  updateProfile,
  sendEmailVerification,
  User,
  ActionCodeSettings,
  getIdTokenResult,
  updateEmail,
  updatePassword,
  // connectAuthEmulator, ErrorFn
} from "firebase/auth";
import { auth, db } from "./firebase.config";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";

// const provider = new GoogleAuthProvider();

// if (window.location.hostname === "localhost") {
//   connectAuthEmulator(auth, "http://127.0.0.1:3000");
// }

const actionCodeSettings: ActionCodeSettings = {
  url: "https://medpharma.care/",
  iOS: {
    bundleId: "com.medpharma",
  },
  android: {
    packageName: "com.medpharma",
    installApp: true,
    minimumVersion: "12",
  },
  handleCodeInApp: true,
};

const Firebase = {
  // auth
  loginWithEmail: async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
  },
  signupWithEmail: async (email: string, password: string) => {
    return await createUserWithEmailAndPassword(auth, email, password);
  },
  sendVerificationEmail: async (user: User) => {
    return await sendEmailVerification(user, actionCodeSettings);
  },
  signOut: async () => {
    return await signOut(auth);
  },
  checkUserAuth: async (user: any) => {
    return onAuthStateChanged(auth, user);
  },
  passwordReset: async (email: string) => {
    return await sendPasswordResetEmail(auth, email);
  },
  updateUserPassword: async (user: User, newPassword: string) => {
    return await updatePassword(user, newPassword);
  },
  currentUser: async () => {
    return auth.currentUser;
  },
  updateUserProfile: async (user: User, data: any) => {
    // const user: any = auth.currentUser;
    // if (!user) throw Alert.alert("Error", "No user logged in");
    return await updateProfile(user, data);
  },
  updateCurrentUser: async (user: any) => {
    return await updateCurrentUser(auth, user);
  },
  updateUserEmail: async (email: string) => {
    const user: any = auth.currentUser;
    if (!user) return await updateEmail(user, email);
  },
  activationCode: async (code: string) => {
    return await applyActionCode(auth, code);
  },
  isTokenExpired: async (): Promise<boolean> => {
    const user = auth.currentUser;
    if (user) {
      try {
        const idTokenResult = await getIdTokenResult(user);
        const expirationTime = parseInt(idTokenResult.expirationTime, 10);
        const currentTime = Date.now() / 1000; // Convert to seconds

        // Check if the token is expired (expiration time is in seconds)
        const tokenExpired = currentTime >= expirationTime;
        return tokenExpired;
      } catch (error) {
        return false;
      }
    }
    return false; // Handle the case where there's no user
  },

  // firestore
  createNewUser: async (userData: any) => {
    const userRef = doc(db, "users", userData.uid); //create a reference
    return await setDoc(userRef, userData);
  },
  updateUser: async (userData: any) => {
    const userRef = doc(db, "users", userData.uid);
    return await updateDoc(userRef, userData);
  },
  getUser: async (id: string) => {
    const userRef = doc(db, "users", id);
    const userSnapshot = await getDoc(userRef); //get document snapshot
    return userSnapshot;
  },
};

export default Firebase;
