import React from "react";
import empty from "../../assets/images/empty.png";
import { Button } from "antd";
import Colors from "../../constants/Colors";
import { useNavigate } from "react-router-dom";

export default function Empty(props: any) {
  const navigate = useNavigate();
  const { title, description, imageStyle, titleStyle, desStyle, buttons } =
    props;

  return (
    <section className="flex flex-col justify-center items-center my-4">
      <img className={imageStyle} src={empty} alt="" />
      <h2
        className={`${
          titleStyle && titleStyle
        } mt-2 font-medium text-center text-gray-800`}
      >
        {title}
      </h2>
      <p className={`${desStyle && desStyle} mt-1 text-center text-gray-600`}>
        {description}
      </p>
      {buttons && (
        <div className="flex flex-col items-center justify-center mt-4 space-y-1 md:flex-row md:space-y-0 md:space-x-1">
          {buttons.map((btn: any, idx: any) =>
            btn.path ? (
              <Button
                key={idx}
                onClick={() => navigate(btn.path)}
                type={btn.type ? btn.type : "primary"}
                style={{ backgroundColor: btn.type ? "" : Colors.primary }}
              >
                {btn.name}
              </Button>
            ) : (
              <Button key={idx} onClick={btn.event}>
                {btn.name}
              </Button>
            )
          )}
        </div>
      )}
    </section>
  );
}
