import React, { useState } from "react";

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import type { UploadProps } from "antd";
import DataProvider from "../../apis";
import Colors from "../../constants/Colors";
import { useAppStore } from "../../store";

// type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

// const getBase64 = (file: FileType): Promise<string> =>
//   new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result as string);
//     reader.onerror = (error) => reject(error);
//   });

// const getBase64 = (img: FileType, callback: (url: string) => void) => {
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result as string));
//   reader.readAsDataURL(img);
// };

// const beforeUpload = (file: FileType) => {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return isJpgOrPng && isLt2M;
// };

export default function UploadPicture(props: any) {
  const { doctor, setDoctor } = useAppStore();
  const { setInnerTab } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const handleChange: UploadProps["onChange"] = (info) => {
    // if (info.file.status === "uploading") {
    //   setLoading(true);
    //   return;
    // }
    // if (info.file.status === "done") {
    //   // Get this url from response in real world.
    //   getBase64(info.file.originFileObj as FileType, (url) => {
    //     setLoading(false);
    //     setImageUrl(url);
    //   });
    // }
  };

  const uploadButton = (
    <button
      className="rounded-full flex flex-col justify-center items-center"
      style={{ border: 0, background: "none" }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const customRequest = async (options: any) => {
    const formData = new FormData();
    formData.append("file", options.file);

    try {
      setLoading(true);
      const result = await DataProvider.cloudUpload(formData);
      setImageUrl(result.data.url);

      // Handle the result and call onSuccess
      options.onSuccess(result.data, options.file);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Handle errors and call onError
      options.onError(error, options.file);
    }
  };

  async function onSaveImage() {
    if (doctor) {
      setLoadingx(true);
      messageApi
        .open({
          type: "loading",
          content: "Profile updating...",
          duration: 2.5,
        })
        .then(async () => {
          try {
            const payload = { picture: imageUrl };
            await DataProvider.updateDoctorProfile(doctor?.id, payload);
            await DataProvider.updateProfile(payload);

            const { data } = await DataProvider.getDoctorProfile();
            setDoctor(data);
            setLoading(false);
            message.success("Profile updated successfully.");
            setInnerTab(null);
          } catch (error: any) {
            setLoading(false);
            message.error("Failed to update profile. Try again later..");
          }
        });
      try {
      } catch (error) {}
    }
  }

  return (
    <div className="w-full md:h-[70vh] h-[50vh] flex flex-col justify-start items-center">
      <p className="mt-12 mb-5 w-80 text-center">
        Click on upload button below to upload your profile image.
      </p>
      <div className="flex justify-center">
        <Upload
          name="avatar"
          listType="picture-circle"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={customRequest}
          //   beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              className="rounded-full"
              style={{ width: "100%" }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>
      <div className="flex my-4">
        <Button onClick={() => setInnerTab(null)} className="">
          Cancel
        </Button>
        {imageUrl === null ? (
          <Button disabled type="primary" className="mx-4">
            Save Image
          </Button>
        ) : (
          <Button
            onClick={onSaveImage}
            type="primary"
            style={{ background: Colors.primary }}
            className="mx-4"
          >
            {loadingx ? "Saving..." : "Save Image"}
          </Button>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
