import Search from "antd/es/input/Search";
import React from "react";

export default function Searchbar(props: any) {
  const { searchText, setSearchText } = props;

  return (
    <Search
      size="large"
      placeholder="Search services by name"
      value={searchText}
      onChange={(e) => setSearchText(e.target.value)}
      allowClear
    />
  );
}
