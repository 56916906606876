import React from "react";
import MainLayout from "../../components/layout/main-layout";
import { Outlet } from "react-router-dom";

export default function Main() {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
}
