/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, PathRouteProps, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppStore } from "../store";

interface AuthRoutesProps extends PathRouteProps {
  // path: any;
  component?: React.ComponentType<any>;
}

const AuthRoutes: React.FC<AuthRoutesProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const { doctor, token, user } = useAppStore();
  const navigate = useNavigate();

  useEffect(() => {
    if ((doctor || user) && token) {
      return;
    } else {
      navigate("/sign-in");
    }
  }, [doctor, token, user]);

  return <Outlet />;
};

export default AuthRoutes;
