import React, { ReactNode, useEffect, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { FaMoneyBill, FaUserFriends } from "react-icons/fa";
// import { ClockCircleOutlined } from "@ant-design/icons";
import AccountInfo from "../../components/sections/account-info";
// import Availability from "../../components/sections/availability";
import PaymentDetails from "../../components/sections/payment-details";

export default function Profile() {
  const navigate = useNavigate();
  const params = useParams();
  const [tab, setTab] = useState("1");

  function tabBar(icon: ReactNode, title: ReactNode) {
    return (
      <div className="flex justify-center items-center font-medium text-sm cursor-pointer">
        {icon} {title}
      </div>
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: tabBar(
        <FaUserFriends />,
        <p className="mx-2">Personal Information</p>
      ),
      children: <AccountInfo tab={tab} />,
    },
    // {
    //   key: "2",
    //   label: tabBar(
    //     <ClockCircleOutlined size={20} />,
    //     <p className="mx-1">Availability</p>
    //   ),
    //   children: <Availability />,
    // },
    {
      key: "4",
      label: tabBar(
        <FaMoneyBill size={20} />,
        <p className="mx-2">Payment Details</p>
      ),
      children: <PaymentDetails tab={tab} />,
    },
    // {
    //   key: "5",
    //   label: tabBar(
    //     <IoChatbox size={17} />,
    //     <p className="mx-2">Contact Us</p>
    //   ),
    //   children: "Content of Tab Pane 3",
    // },
  ];

  const onChange = (key: string) => {
    setTab(key);
    navigate(`/profile/${key}`);
  };

  useEffect(() => {
    const { id } = params;
    if (id) {
      setTab(id);
    }
  }, [params]);

  return (
    <div className="my-4">
      <Tabs
        animated
        activeKey={tab}
        type="card"
        className="w-full"
        items={items}
        onChange={onChange}
        indicator={{ size: 200 }}
      />
    </div>
  );
}
