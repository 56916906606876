import React, { useCallback, useEffect, useState } from "react";
import { useAppStore } from "../../store";
import DataProvider from "../../apis";
// import { Pagination, PaginationProps } from "antd";
import Loader from "../elements/loader";
import Empty from "../elements/empty";

export default function Payouts() {
  const { doctor } = useAppStore();
  const [data, setData] = useState<any[] | null>(null);
  const [loading, setLoading] = useState(false);
  // const [totalPages, setTotalPages] = useState<number>(1);
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [pageSize, setPageSize] = useState<number>(9);

  const fetchData = useCallback(async () => {
    if (doctor) {
      setLoading(true);
      try {
        const results = await DataProvider.getPayouts(doctor?.id);
        setData(results.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
      }
    }
  }, [doctor]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // const onChange: PaginationProps["onChange"] = (page) => {
  //   setCurrentPage(page);
  // };
  return (
    <div className="bg-white p-4 border md:w-[48%] w-full min-h-56 mb-4 rounded-md">
      <h3 className="font-medium">Payouts</h3>
      {loading && data === null ? (
        <div className="w-full flex flex-col justify-center mt-3">
          <Loader num={3} />
        </div>
      ) : data && data.length > 0 ? (
        <div>
          <div className="flex md:justify-start justify-center flex-wrap">
            {data.map((v, idx) => (
              <div
                key={idx}
                className="border p-2 my-2 rounded-lg flex justify-between"
              >
                <div>
                  <h3 className="font-medium">Appointment session revenue</h3>
                  <p className="text-gray-400">20 March, 2024</p>
                </div>
                <p className="text-green-500 font-medium">GH₵65.00</p>
              </div>
            ))}
          </div>
          <div className="w-full flex justify-center my-4">
            {/* <Pagination
              current={currentPage}
              onChange={onChange}
              total={totalPages}
            /> */}
          </div>
        </div>
      ) : (
        <div className="">
          <Empty
            imageStyle="md:h-44 h-24"
            titleStyle="md:text-lg text-sm"
            desStyle="md:text-base"
            title="Empty"
            buttons={[
              // {
              //   name: "Request Payout",
              //   event: () => {},
              // },
              {
                name: "Refresh",
                event: () => fetchData(),
              },
            ]}
            description="No payouts available."
          />
        </div>
      )}
    </div>
  );
}
