import React from "react";

export default function Loader({ num }: { num: number }) {
  return (
    <div className="">
      {Array.from({ length: num }, (_, i) => i + 1).map((i, idx) => (
        <div key={idx} className="flex justify-evenly items-center my-4">
          <div className="mb-2 mr-5">
            <div className="w-12 h-2 bg-gray-200 rounded-lg"></div>
            <div className="w-20 h-2 mt-2 bg-gray-200 rounded-lg"></div>
            <div className="w-16 h-2 mt-2 bg-gray-200 rounded-lg"></div>
            <div className="w-20 h-2 mt-2 bg-gray-200 rounded-lg"></div>
          </div>
          <div>
            <div className="h-2 mt-2 bg-gray-300 rounded-lg w-56"></div>
            <div className="w-40 h-2 mt-2 bg-gray-200 rounded-lg"></div>
            <div className=" w-48 h-2 mt-2 bg-gray-200 rounded-lg"></div>
            <div className=" w-72 h-2 mt-2 bg-gray-200 rounded-lg"></div>
          </div>
        </div>
      ))}
    </div>
  );
}
