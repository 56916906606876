import React, { useState } from "react";
import med from "../../assets/images/logo.png";
import AccountSetup from "../../components/sections/account-setup";
import ProfileImageSetup from "../../components/sections/profile-image-setup";

export default function SetUpAccount() {
  const [tab, setTab] = useState(0);

  return (
    <div className="h-full w-full">
      <div className="absolute top-4 left-4">
        <img src={med} className="h-8 w-28" alt="mcare" />
      </div>

      <div className="bg-white rounded-lg w-full h-[85vh] flex justify-center py-2">
        {tab === 0 && <AccountSetup tab={tab} setTab={setTab} />}
        {tab === 1 && <ProfileImageSetup tab={tab} setTab={setTab} />}
      </div>
    </div>
  );
}
