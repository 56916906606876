import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomCalendar from "../../components/elements/custom-calendar";
import { Button } from "antd";

export default function Reschedule() {
  const navigate = useNavigate();
  // const location = useLocation();
  //   const doctor = location.state.doctor;

  // const [tab, setTab] = useState<number>(0);
  // const [loading, setLoading] = useState<boolean>(false);
  // const [data, setData] = useState<any[]>([]);
  const [schedule, setSchedule] = useState<any>(null);
  const [time, setTime] = useState<any>(null);

  function _onProceed() {
    // setPersist({ ...persist, time, schedule });
    // setTab(1);
  }

  return (
    <div className="pt-3">
      <div className="bg-white border rounded-lg w-full lg:h-[85vh] min-h-[85vh] flex justify-center py-3">
        <div className="flex justify-center">
          <div className="md:w-[50%] w-[90%]">
            <h3 className="font-semibold text-sm text-gray-600 my-2">
              Set a new schedule
            </h3>
            <div className="">
              <CustomCalendar setSchedule={setSchedule} schedule={schedule} />
            </div>
            <div>
              <h3 className="font-semibold text-sm text-gray-600 mt-4 mb-2">
                Select a time
              </h3>
              <div className=" flex justify-start w-full flex-wrap">
                {[
                  "9:00am - 9:15am",
                  "9:25am - 9:40am",
                  "9:50am - 10:05am",
                  "10:15am - 10:30am",
                  "10:40am - 10:55am",
                ].map((v, idx) => (
                  <span
                    key={idx}
                    onClick={() => setTime(v)}
                    className={`${
                      v === time ? "border-[#0081D4] border-2 bg-[#F0F9FF]" : ""
                    } border p-2 m-2 rounded-md cursor-pointer`}
                  >
                    {v}
                  </span>
                ))}
              </div>

              <div className="my-5">
                <Button
                  onClick={_onProceed}
                  type="primary"
                  size="large"
                  color="#0081D4"
                  htmlType="submit"
                  className="bg-[#0081D4] w-full"
                >
                  Save
                </Button>

                <Button
                  size="large"
                  onClick={() => navigate(-1)}
                  className="w-full my-3"
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
